import React from "react";
import {
  RiTwitterFill,
  RiFacebookFill,
  RiLinkedinFill,
  RiInstagramFill,
  RiYoutubeFill,
} from "react-icons/ri";
import { Link, NavLink } from "react-router-dom";

const Footer = () => {
  return (
    <footer className="bg-soft-primary">
      <div className="container py-13 py-md-15">
        <div className="row gy-6 gy-lg-0">
          <div className="col-md-6 col-lg-6">
            <div className="widget">
              <div className="row my-4">
                <div className="col-md-4 col-6">
                  <img
                    className="mb-4 img-fluid"
                    src="img/logo.png"
                    alt=""
                    style={{ width: "width: 161px" }}
                  />
                  <a
                    href="https://play.google.com/store/apps/details?id=com.sahisavings"
                    target={"_blank"}
                  >
                    <img className="img-fluid" src="img/play.svg" />
                  </a>
                </div>
                <div className="col-md-4"></div>
              </div>
              <div>
                <p style={{ marginBottom: "10px" }}><b>K2VS Finance and Investment Private Limited</b></p>
                <p style={{ marginBottom: "10px" }}>
                  
                  <b>[ARN - 190825] [Platform Partner : BSE (Member code-54418)]
                  </b>
                </p>
                <p style={{ marginBottom: "10px" }}>
                  <b>
                    [CIN - U67100MH2021PTC366575] [GST No : 27AAICK9851E1Z7]
                  </b>
                </p>
                <p>
                  <b>Want your personal & investment details to be deleted? Please <Link to="/AccountDeletion" target="_blank">click here</Link></b>
                </p>
              </div>
              <nav className="nav social text-reset">
                <Link to="/privacypolicy" style={{ fontSize: "0.8rem" }}>
                  Privacy Policy
                </Link>
                <Link to="/refundcan_cellation" style={{ fontSize: "0.8rem" }}>
                  Refund & Cancellation
                </Link>
                <Link to="/termscondition" style={{ fontSize: "0.8rem" }}>
                  Terms & Condition
                </Link>
              </nav>
            </div>
          </div>
          <div className="col-md-2 col-lg-2">
            <div className="widget">
              <h4 className="widget-title  mb-3">USEFUL LINKS</h4>
              <ul className="list-unstyled text-reset mb-0">
                <li>
                  <NavLink to="/">Home</NavLink>
                </li>
                <li>
                  <NavLink to="/about">About Us</NavLink>
                </li>
                <li>
                  <NavLink to="/services">Services</NavLink>
                </li>
                <li>
                  <NavLink to="/contact">Contact Us</NavLink>
                </li>
                <li>
                  <NavLink to="/blogs">Articles</NavLink>
                </li>
              </ul>
            </div>
           </div>
          <div className="col-md-4 col-lg-4">
            <div className="widget">
              <h4 className="widget-title  mb-3">KEEP IN TOUCH</h4>
              <address className="">
                Flat no 15 B Wing Andheri Indradarshan Near St Louis School Four
                Bungalows Andheri West Mumbai 400053
              </address>
              <a href="mailto:support@sahisavings.com" className="link-body">
                support@sahisavings.com
              </a>
              <br />
              <a href="tel:+91 8976120023" className="link-body">
                +91 8976120023
              </a>
              <nav className="nav social">
                <a target={"_blank"} href="https://twitter.com/SahiSavings">
                  <RiTwitterFill style={{ color: "#009ded" }} />
                </a>
                <a
                  target={"_blank"}
                  href="https://www.facebook.com/sahisavingsofficial/"
                >
                  <RiFacebookFill style={{ color: "#1e4695" }} />
                </a>
                <a
                  target={"_blank"}
                  href="https://www.linkedin.com/company/sahisavings"
                >
                  <RiLinkedinFill style={{ color: "#2764ac" }} />
                </a>
                <a
                  target={"_blank"}
                  href="https://www.instagram.com/sahisavings/"
                >
                  <RiInstagramFill style={{ color: "#f71c59" }} />
                </a>
                <a
                  target={"_blank"}
                  href="https://www.youtube.com/channel/UC9MbnDmeFHY0RNOmsgW5BDw"
                >
                  <RiYoutubeFill style={{ color: "#df2016" }} />
                </a>
              </nav>
            </div>
          </div>
          <div className="col-md-12">
            <div className="mt-4">
              <p>
                Sahi Savings makes no warranties or representations, express or
                implied, on products and services offered through the platform.
                It accepts no liability for any damages or losses, however,
                caused in connection with the use of, or on the reliance of its
                advisory or related services. Past performance is not indicative
                of future returns. Please consider your specific investment
                requirements, risk tolerance, goal, time frame, risk and reward
                balance and the cost associated with the investment before
                choosing a fund or designing a portfolio that suits your needs.
                Performance and returns of any investment portfolio can neither
                be predicted nor guaranteed. Investment in securities market are
                subject to market risks, read all the related documents
                carefully before investing.
              </p>
            </div>
            <div className="widget">
              <div className="mb-3 text-sm mt-4">
                <span
                  className="text-brand-white inline font-semibold"
                  style={{
                    position: "fixed",
                    right: "8px",
                    bottom: "70px",
                  }}
                >
                  <a href="https://api.whatsapp.com/send?phone=918976120023&text=Hey Sahi Savings!">
                    <img
                      src="img/whatsapp.png"
                      alt="whatsapp"
                      style={{
                        width: "55px",
                      }}
                    />
                  </a>
                </span>
              </div>
              <div className="mb-3 text-sm">
                <span className="text-brand-white inline font-semibold"></span>
              </div>
            </div>
          </div>
        </div>
      </div>
    </footer>
  );
};

export default Footer;
