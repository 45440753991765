import React from "react";
import Fade from "react-reveal/Fade";

const Testicard = (props) => {
  return (
    <>
      <Fade bottom>
        <div>
          <div className="card shadow-lg">
            <div className="card-body">
              <span className="ratings five mb-3"></span>
              <blockquote className="icon mb-0">
                <p>{props.revew}</p>
                <div className="blockquote-details">
                  <img className="rounded-circle w-12" src={props.img} alt="" />
                  <div className="info">
                    <h5 className="mb-1">{props.name}</h5>
                    <p className="mb-0">{props.subtitle}</p>
                  </div>
                </div>
              </blockquote>
            </div>
          </div>
        </div>
      </Fade>
    </>
  );
};

export default Testicard;
