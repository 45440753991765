import React from 'react';

const Refundcancellation = () => {
  return (
    <>
      <section className="bg-light angled upper-end lower-end">
        <div className="container pt-20">
          <h2 className="mb-5 text-center" style={{ color: '#205d88' }}>
            REFUND & CANCELLATION
          </h2>
          <p>Last updated: May 28, 2022</p>
          <p>
            Interpretation and Definitions
            <br />
            ==============================
          </p>
          <p>
            Interpretation
            <br />
            ————–
          </p>
          <p>
            The words of which the initial letter is capitalized have meanings
            defined
            <br />
            under the following conditions. The following definitions shall have
            the same
            <br />
            meaning regardless of whether they appear in singular or in plural.
          </p>
          <p>
            Definitions
            <br />
            ———–
          </p>
          <p>For the purposes of this Return and Refund Policy:</p>
          <p>
            “<strong>Company</strong>” (referred to as either “the Company”,
            “We”, “Us” or “Our” in this
            <br />
            Agreement) refers to K2VS Finance And Investment Private Limited,
            Flat No
            <br />
            15 B Wing Andheri Indradarshan Four Bungalows Andheri West Mumbai
            400053.
          </p>
          <p>
            “<strong>Orders</strong>” mean a request by You to purchase Goods
            from Us.
          </p>
          <p>
            “<strong>Service</strong>” refers to the Website and Mobile App.
          </p>
          <p>
            “<strong>Website</strong>” refers to sahisavings.com, accessible
            from &lt;https://sahisavings.com&gt;
          </p>
          <p>
            “<strong>User</strong>” means the individual accessing or using the
            Service, or the company,
            <br />
            or other legal entity on behalf of which such individual is
            accessing or
            <br />
            using the Service, as applicable.
          </p>
          <p>
            Order Cancellation Rights
            <br />
            ==============================
          </p>
          <p>
            The User are entitled to cancel Order within 7 days without giving
            any reason
            <br />
            for doing so.
          </p>
          <p>
            The deadline for cancelling an Order is 7 days from the date on
            which the user received the Goods or on which a third party you have
            appointed, who is not
            <br />
            the carrier, takes possession of the product delivered.
          </p>
          <p>
            In order to exercise right of cancellation, user must inform Us of
            decision by means of a clear statement. User can inform us of your
            decision by:
          </p>
          <ul>
            <li>By email: support@sahisavings.com</li>
            <li>
              By visiting this page on our website:
              &lt;https://sahisavings.com/&gt;
            </li>
          </ul>
          <p>
            We will reimburse user no later than 14 days from the day on which
            We receive
            <br />
            the returned Goods. We will use the same means of payment as user
            used for the
            <br />
            Order, and user will not incur any fees for such reimbursement.
          </p>
          <p>
            Conditions for Returns
            <br />
            ======================
          </p>
          <p>
            In order for the Goods to be eligible for a return, please make sure
            that:
          </p>
          <ul>
            <li>The Goods were purchased in the last 7 days</li>
            <li>The Goods are in the original packaging</li>
          </ul>
          <p>The following Goods cannot be returned:</p>
          <ul>
            <li>
              The supply of Goods made to users specifications or clearly
              personalized.
            </li>
            <li>
              The supply of Goods which according to their nature are not
              suitable to be returned, deteriorate rapidly or where the date of
              expiry is over.
            </li>
            <li>
              The supply of Goods which are not suitable for return due to
              health
              <br />
              protection or hygiene reasons and were unsealed after delivery.
            </li>
            <li>
              The supply of Goods which are, after delivery, according to their
              nature,
              <br />
              inseparably mixed with other items.
            </li>
          </ul>
          <p>
            We reserve the right to refuse returns of any merchandise that does
            not meet
            <br />
            the above return conditions in our sole discretion.
          </p>
          <p>
            Only regular priced Goods may be refunded. Unfortunately, Goods on
            sale cannot
            <br />
            be refunded. This exclusion may not apply to user if it is not
            permitted by
            <br />
            applicable law.
          </p>
          <p>
            Returning Goods
            <br />
            ===============
          </p>
          <p>
            User are responsible for the cost and risk of returning the Goods to
            Us. You
            <br />
            should send the Goods at the following address:
          </p>
          <p>Andheri West</p>
          <p>
            We cannot be held responsible for Goods damaged or lost in return
            shipment.
            <br />
            Therefore, We recommend an insured and trackable mail service. We
            are unable
            <br />
            to issue a refund without actual receipt of the Goods or proof of
            received
            <br />
            return delivery.
          </p>
          <p>
            Contact Us
            <br />
            ———-
          </p>
          <p>
            If you have any questions about our Returns and Refunds Policy,
            please contact
            <br />
            us:
          </p>
          <p>* By email: support@sahisavings.com</p>
          <p>
            * By visiting this page on our website:
            &lt;https://sahisavings.com/&gt;
          </p>
        </div>
      </section>
    </>
  );
};

export default Refundcancellation;
