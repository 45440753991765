import React from "react";

const Sectionhra = () => {
  return (
    <div
      style={{
        padding: "10%",
        justifyContent: "center",
        textAlign: "justify",
      }}
    >
      <p><b>A salaried individual having a rented accommodation can get the benefit of
      HRA (House Rent Allowance). </b>
      </p>
      <p>
      This could be totally or partially exempted
      from income tax. However, if you aren’t living in any rented accommodation
      and still continue to receive HRA, it will be taxable. If you couldn’t
      submit rent receipts to your employer as proof to claim HRA, you can still
      claim the exemption while filing your income tax return. So, please keep
      rent receipts and evidence of any payment made towards rent.  </p>
      <p>
      <p>You can claim the least of the following as HRA exemption. </p>
      <ul>a. Total HRA received from your employer </ul>
      <ul>b. Rent paid less 10% of (Basic salary +DA) </ul>
      <ul>c. 40% of salary
      (Basic salary+DA) for non-metros and 50% of salary (Basic salary+DA) for
      metros</ul>
      </p>
    </div>
  );
};

export default Sectionhra;
