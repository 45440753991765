import React from "react";

const Section80E = () => {
  return (
    <div
      style={{
        padding: "10%",
        justifyContent: "center",
        textAlign: "justify",
      }}
    >
      <p>
        <b>What is Section 80E?</b> 
        <p>If you have taken an education loan and are
        repaying the same, then the interest paid on that education loan is
        allowed as a deduction from the total income under Section 80E.</p>
      </p>

      <p>
        The important point to be noted here is that the deduction provided is
        only for the interest part of the EMI. There is no tax benefit for the
        principal part of the EMI.
      </p>

      <p>
        <b>Who can claim this deduction?</b>{" "}
        <li>
          Only individuals can claim this deduction. It is not available to HUF
          or any other kind of taxpayer.
        </li>{" "}
        <li>
          The loan should be taken for the higher education of self, spouse or
          children or for a student for whom the individual is a legal guardian.
        </li>{" "}
        <li>
          Parents can easily claim this deduction for the loan taken for the
          higher studies of their children.
        </li>
      </p>

      <p>
        <b>Where can this loan be availed?</b> 
        <p>The loan needs be availed from
        any bank / financial institution or any approved charitable
        institutions. Loans availed from friends or relatives do not qualify for
        Section 80E deduction.</p>
      </p>

      <p>
        <b>The purpose of the loan</b>
        <li>
          The loan should be availed to pursue higher studies. It does not
          matter whether such an education loan is taken for higher studies in
          India or outside India.
        </li>
        <li>
          Higher studies include all the fields of study pursued after passing
          the senior secondary examination or its equivalent exam. It includes
          both vocational as well as regular courses.
        </li>
      </p>

      <p>
        <b>Deduction amount Allowed</b>{" "}
        <li>
          The deduction allowed is the total interest part of the EMI paid
          during the financial year. There is no limit on the maximum amount
          that is allowed as deduction.
        </li>{" "}
        <li>
          You, however, need to obtain a certificate from your Bank. Such a
          certificate should clearly mention the principal and the interest
          portion of the education loan paid by you during the financial year.
        </li>{" "}
        <li>
          The total interest paid in a FY will be allowed as a deduction. No Tax
          benefit is allowed for the principal repayment.
        </li>
      </p>

      <p>
        <b>Period of deduction</b>{" "}
        <li>
          The deduction for the interest on loan starts from the year in which
          you start repaying the loan.
        </li>
         
        <li>
          It is available only for 8 years starting from the year in which you
          start repaying the loan or until the interest is fully repaid
          whichever is earlier.
        </li>{" "}
        <li>
          This means if the complete repayment of the loan is done in 5 years
          only, then tax deduction will be allowed for 5 years and not 8 years.
        </li>{" "}
        <li>
          It should also be noted that if your loan tenure exceeds 8 years, then
          you cannot claim a deduction for the interest paid beyond 8 years. So
          it is always advisable that an education loan is paid within eight
          years.
        </li>
      </p>
    </div>
  );
};

export default Section80E;
