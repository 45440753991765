const About = [
  {
    id: "1",
    dis: "We are personal finance management company that believes in          solving problem that affects Salaried Individual the most                  i.e., Taxes!",
  },
  {
    id: "2",
    dis: "Track & Manage Investment into various assets available in India, Life, Health & General Insurance & Home, Personal, Vehicle, Education & Business Loans.",
  },
  {
    id: "3",
    dis: "Pay utility bills as well as Insurance Premium of Policies whether it is Life, Health or General.",
  },
  {
    id: "4",
    dis: "Track various loans availed and have a holistic view of principal & interest paid for the loans and ways to pre-pay your home loans.",
  },
];

export default About;
