import React from 'react';

const Privacypolicy = () => {
  return (
    <>
      <section className="bg-light angled upper-end lower-end">
        <div className="container pt-20">
          <h2 className="mb-5 text-center" style={{ color: '#205d88' }}>
            PRIVACY POLICY
          </h2>
          <p>
            We at Sahi Savings endeavour to ensure the confidentiality,
            integrity, and security of you and your family’s personal
            information, at all times. We want you to understand how we protect
            your privacy when we collect and use your personal information in
            the course of business, as well as the measures we take to safeguard
            your personal information.
          </p>
          <p>
            <strong>HOW WE COLLECT AND USE YOUR PERSONAL INFORMATION</strong>
          </p>
          <p>
            <strong>Pre-registration</strong>&nbsp;– You cannot access any
            section or features of Sahi Savings without registering or
            disclosing any personal information. As and when you download and
            install the app, remember that we capture anonymous visitor data
            such as pattern of your usage of app. We use this data together with
            similar data from other visitors to understand and control the usage
            of the app only in certain areas where our services are currently
            available.
          </p>
          <p>
            <strong>Registration&nbsp;</strong>– To access the Sahi Savings
            application, you will be asked to register yourself. This involves
            creating a Login Credential using Mobile Number &amp; Passcode
            (collectively, “Login Credentials”)
          </p>
          <p>
            <strong>Investment</strong>&nbsp;– To avail the functionality of our
            investment services and start investing, you will require to provide
            us with certain additional information such as:
          </p>
          <ul>
            <li>
              Contact Details such as name, father’s name, age, e-mail address
              etc.
            </li>
            <li>
            Profile Picture: The App requests access to your device's camera and storage to capture and upload your profile picture. The camera permission is required to capture the image, and the storage access is necessary to save and upload the image to our servers.
            We use the profile picture you provide through the camera and storage access solely for the purpose of displaying it within the App and associated user account features. We do not use your profile picture for any other purpose without your explicit consent.
            </li>
            <li>
              Demographic Data such as your mailing address, pin code etc.
            </li>
            <li>Bank account details with which you will be investing</li>
            <li>
              Any other documents required under KYC norms such as PAN card
              details, Aadhar Number etc.
            </li>
          </ul>
          <p>(collectively, your “Registration Information”).</p>
          <p>
            We further need photographic evidence verifying your bank account
            such as image of the first page of the passbook or any cancelled
            cheque in order to verify that the bank account belongs to the
            Customer.
          </p>
          <p>
            Kindly provide us with the correct banking details as upon
            withdrawal any money received by the Customer gets deposited
            directly to your mentioned bank account. We may also ask for
            additional personal information from time to time to meet new
            regulatory requirements or to provide you with other benefits of our
            Services.
          </p>
          <p>
            In case you wish to track investments made outside Sahi Savings, we
            will require your E-mail Id registered with the mutual fund folios.
            We use this Email Id to generate and deliver your mutual fund
            account statements that will be delivered to your inbox. Our systems
            automatically read the relevant email with the subject “CONSOLIDATED
            ACCOUNT STATEMENT – CAMS/KARVY Mail back Request” to process your
            investment information seamlessly and display it on the app for you
            to track. We assure you that emails with any other subject apart
            from the one mentioned above are not read/opened by us. In case, you
            do not wish to provide us with your E-mail id registered with the
            mutual funds, you will be required to manually upload your
            investment related information on our application to access our
            services and keep track of the investments made without using our
            platform.
          </p>
          <p>
            Sahi Savings will use information to better serve you and enhance
            your experience with the Services. Here are some examples of the
            ways we use your information:
          </p>
          <ul>
            <li>To administer your account;</li>
            <li>To send you investment related information.</li>
            <li>To provide you monthly investment statement.</li>
            <li>To conduct research and analysis.</li>
            <li>To respond to customer service inquiries.</li>
            <li>To send marketing materials, if opted.</li>
            <li>To improve our access to the App.</li>
            <li>To meet any regulatory requirements.</li>
            <li>
              To facilitate the processing of a data between you and a third
              party.
            </li>
          </ul>
          <p>
            <strong>SHARING YOUR PERSONAL INFORMATION</strong>
          </p>
          <p>
            Under no circumstance will we sell or rent your personal information
            to anyone, for any reason, at any time. Access to your Login
            Credentials, Registration Information, and any other personal
            information you provide is restricted and used in accordance with
            specific internal procedures in order to operate, develop or improve
            our services.
          </p>
          <p>
            We share your investment related information to Bombay Stock
            Exchange in order to verify your credentials to meet our regulatory
            obligations.
          </p>
          <p>
            We may also use third party vendors, professionals such as advisors
            or service providers to help us provide services to you, such as
            maintaining the track record of your investment or couriering
            documents to you on our behalf or operating the payment gateway
            functionality of the website. Such third parties are required to
            maintain the confidentiality of the information we provide to them
            under contract and under law.
          </p>
          <p>
            If your advisor terminates his or her relationship with us and moves
            to another investment advisory firm (“New Firm”), we or your advisor
            may disclose your personal information to the New Firm, unless you
            instruct us not to.
          </p>
          <p>
            We reserve the right to disclose your personal information in the
            scope and in situations as required by the law and when we believe
            in good faith that such disclosure is necessary to protect our
            rights, to report suspected illegal activity, comply with judicial
            proceedings, court orders or decisions, or legal process served on
            our website.
          </p>
          <p>
            We may also use Your information, and/ or permit selected third
            parties including service providers, on the Website, with Your prior
            consent, to use Your information or provide You with such
            information which may be of interest to You and We and/ or they may
            contact You about the same. If You wish to be so contacted by the
            third parties, any information You provide to such third parties may
            be read, collected, and used by them. Notwithstanding the
            abovementioned, You may choose to stop receiving such information
            from the third parties by writing to us at “
            <a href="mailto:support@sahisavings.com">support@sahisavings.com</a>
            ”
          </p>
          <br />
          <p>
            <strong>THIRD PARTY WEBSITES</strong>
          </p>
          <p>
            Our website may, from time to time, contain links to and from the
            websites of Our partner networks, affiliates and other third
            parties. This Privacy Policy does not cover the collection of
            information by cookies or other methods by third parties. We do not
            control how such third parties collect information or by what means
            as such third parties may use their own cookies to collect
            information about you. Please remember that after we disclose any
            personal information to third parties, we can no longer control the
            use of such personal information. Accordingly, Sahi Savings will not
            be liable for the acts or omissions of any third party. Please check
            these policies and the terms of the websites before You submit any
            information to these websites.
          </p>
          <p>
            <strong>INFORMATION STORAGE AND SECURITY</strong>
          </p>
          <p>
            We have implemented a wide range of measures in order to ensure the
            security and confidentiality of your data. These include physical,
            electronic and administrative safeguards such as firewalls, data
            encryption, SSL and other up-to-date technologies.
          </p>
          <p>
            No security measures, however, are 100% complete. Therefore, we do
            not promise and cannot guarantee, and thus you should not expect,
            that your personal information or private communications will not be
            collected and used by others. You should take steps to protect
            against unauthorized access to your password, phone, and computer
            by, among other things, signing off after using a shared computer,
            choosing a robust password that nobody else knows or can easily
            guess, and keeping your log-in and password private. We are not
            responsible for the unauthorized use of your information or for any
            lost, stolen, compromised passwords, or for any activity on your
            Account via unauthorized password activity.
          </p>
          <p>
            We cannot guarantee the security of your data while it is being
            transmitted over the Internet and through servers that are out of
            our control. We do our best to protect your personal information but
            we cannot ensure or warrant the security of any information you
            transmit to our Website or Services. Therefore, if you make any data
            transmissions over the Internet, you are doing it at your own risk.
            Once we receive the data transmission, we make our best efforts to
            ensure its security and privacy on our systems.
          </p>
          <br />
          <p>
            <strong>
              HOW YOU CAN ACCESS AND UPDATE YOUR PERSONAL INFORMATION
            </strong>
          </p>
          <p>
            Your personal information is yours. To access or update your
            information, please login and make changes as required. Some of
            these changes may require you to provide documentary evidence. As an
            added security measure, we may require additional proof of identity
            prior to such disclosure. Remember to keep your Login Credentials
            safe and secret to prevent unauthorized access to your Sahi Savings
            account. If you think that the security of your account has been
            compromised, change your passcode and contact us immediately for
            further assistance.
          </p>
          <br />
          <p>
            <strong>OUR LEGAL OBLIGATION TO YOU</strong>
          </p>
          <p>
            We are bound by the (Indian) Information Technology Act, 2000, and
            comply with all its provisions. Under Regulation 4 of the
            Information Technology (Reasonable Security and Procedures and
            Sensitive Personal Data or Information) Rules, 2011, K2VS Finance
            and Investment Pvt. Ltd. is obliged to provide every registered user
            of Sahi Savings with a readable copy of the information that it
            keeps about you. If you would like a copy, do get in touch with us
            at the address provided below.
          </p>
          <br />
          <p>
            <strong>CHANGES TO THIS PRIVACY POLICY</strong>
          </p>
          <p>
            This Privacy Policy is published on the app in compliance with
            Regulation 4 of the (Indian) Information Technology (Reasonable
            Security Practices and Procedures and Sensitive Personal
            Information) Rules, 2011.
          </p>
          <p>
            We update this Privacy Policy periodically. We will not reduce your
            rights under this Privacy Policy without your explicit consent. We
            will notify you of any changes to this Privacy Policy.
          </p>
          <p>
            The date of last revision appears at the top and all changes take
            effect immediately upon posting. For the purposes of this policy,
            “personal information” means any information that can be used to
            identify you personally.
          </p>
          <br />
          <p>
            <strong>TERMINATION</strong>
          </p>
          <p>
            If your relationship with us ends with us, we will continue to treat
            and protect your personal information in accordance with this
            Privacy Policy. That means that we may continue to share your
            personal information with our partners and affiliates as described
            above or permitted by law. However, if you notify us to stop sharing
            your personal information with others, we will honour that request
            <strong>.</strong>
          </p>
          <br />
          <p>
            <strong>APPLICABLE LAW AND JURISDICTION</strong>
          </p>
          <p>
            This Privacy Policy shall be governed by, construed and entered in
            accordance with the laws of the Republic of India, without regard to
            choice of law or conflict of law provisions thereof.
          </p>
          <p>
            All disputes with respect to this Privacy Policy shall be brought
            and heard in the courts situated at Chennai, India.
          </p>
          <p>&nbsp;</p>
          <p>
            IF YOU HAVE ANY QUESTIONS REGARDING THIS POLICY, DO GET IN TOUCH
            WITH US AT
          </p>
          <p>
            Email ID –&nbsp;
            <a href="mailto:support@sahisavings.com">support@sahisavings.com</a>
          </p>
          <p>Phone Number – +91-8976120023</p>
          <p>Our Registered office address is as follows –</p>
          <p>K2VS Finance and Investment Pvt Ltd.</p>
          <p>Flat No 15 B Wing Andheri Indradarshan</p>
          <p>Convent Avenue Road, Four Bungalows</p>
          <p>Andheri West Mumbai 400053</p>
        </div>
      </section>
    </>
  );
};

export default Privacypolicy;
