import React from "react";

const Notfound = () => {
  return (
    <>
      <section className="wrapper bg-light">
        <div className="container text-center">
          <img src="img/404.svg" alt="404" style={{ width: "90%" }} />
        </div>
        
      </section>
    </>
  );
};

export default Notfound;
