import React from "react";

const Section80DD = () => {
  return (
    <div
      style={{
        padding: "10%",
        justifyContent: "center",
        textAlign: "justify",
      }}
    >
      <p>
        Deduction under Section 80DD of the income tax act is allowed only to
        Resident Individuals or HUFs for a dependant-who is differently-abled
        and is wholly dependent on the individual (or HUF) for support &amp;
        maintenance.
      </p>

      <p>
        Below are the conditions you must meet to avail this deduction &ndash;
      </p>

      <p>
        <li>
          Deduction is allowed for a dependant of the taxpayer and not the
          taxpayer himself.
        </li>{" "}
        <li>
          The deduction can only be claimed by resident individuals of India.
        </li>{" "}
        <li>
          The taxpayer is not allowed this deduction if the dependant has
          claimed a deduction under section 80U for himself/herself.
        </li>{" "}
        <li>
          Dependant in case of an individual taxpayer means spouse, children,
          parents, brothers &amp; sisters of the taxpayer. In case of a HUF
          means a member of the HUF.
        </li>{" "}
        <li>
          The taxpayer has incurred expenses for medical treatment (including
          nursing), training &amp; rehabilitation of the differently-abled
          dependant or the taxpayer may have deposited in a scheme of LIC or
          another insurer for maintenance of the dependant.
        </li>{" "}
        <li>Disability of the dependant is not less than 40%.</li>{" "}
        <li>
          Disability is defined under section 2(i) of the Persons of
          Disabilities Act, 1995.
        </li>
      </p>

      <p>
        What is the maximum amount of deduction allowed under Section 80DD?
        Fixed amount of deductions are allowed under Section 80DD, irrespective
        of the actual expenditure. However, the amount of deduction depends on
        the severity of the disability.
      </p>

      <p>
        <li>
          Where the disability is more than 40% and less than 80%&ndash; Rs
          75,000.
        </li>
        <li>Where the disability is more than 80%&ndash; Rs 1,25,000.</li>
      </p>

      <p>
        Note: Before the Financial Year (FY) 2015-16 (FY 2014-15 &amp; earlier
        years) &ndash; The deduction limit was Rs 50,000 where disability was at
        least 40% and Rs 1,00,000 where there was more than 80% disability.
      </p>

      <p>
        <b>Disabilities covered under Section 80DD</b>
      </p>

      <p>
        <li>Mental illness</li> 
        <li>Autism</li> 
        <li>Hearing impairment</li>
        <li>Mental retardation</li> 
        <li>Cerebral palsy</li>
        <li>Leprosy-cured</li> 
        <li>Blindness</li> 
        <li>Low vision</li>
        <li>Locomotor disability</li>
        </p>
    </div>
  );
};

export default Section80DD;
