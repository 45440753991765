import React from "react";
import Carousel from "./Carousel";
import "owl.carousel/dist/assets/owl.carousel.css";
import "owl.carousel/dist/assets/owl.theme.default.css";
import Fade from "react-reveal/Fade";
import Flash from "react-reveal/Flash";
import Aboutsection from "../Components/Aboutsection";
import Servicesec from "../Components/Servicesec";
import Testimonial from "./Testimonial";
import Compani from "../data/Compani";
import { RiGooglePlayLine } from "react-icons/ri";

const Home = () => {
  return (
    <>
      <Carousel />
      <Aboutsection />
      <Servicesec
        bg={"wrapper bg-soft-primary"}
        height={"row gx-lg-8 gx-xl-10 mb-lg-19 mb-xl-22 align-items-center"}
      />
      <Testimonial />

      <section className="wrapper bg-soft-primary">
        <div className="container py-5 md-py-14">
          <h2 className="fs-15 text-uppercase text-muted text-center mb-8">
            Partnered with
          </h2>
          <div className="px-lg-5 mb-8">
            <div
              className="row align-items-center"
              style={{ justifyContent: "center" }}
            >
              {Compani.map((comp) => {
                return (
                  <div className="col-4 col-md-2 p-1 md-p-3" key={comp.id}>
                    <Fade left>
                      <figure className="px-2 px-md-0">
                        <img src={comp.src} alt="" />
                      </figure>
                    </Fade>
                  </div>
                );
              })}
            </div>
          </div>
        </div>
      </section>

      <section className="wrapper bg-light">
        <div className="container pt-10 pb-15 py-lg-17 py-xl-20 py-xxl-22 position-relative">
          <img
            className="w-76 mr-5 position-lg-absolute col-12 col-lg-12 mt-lg-n50p mb-3 mb-md-10 mb-lg-0 top_img1"
            src="img/devices2.webp"
            alt=""
          />

          <div className="row gx-lg-8 gx-xl-12 align-items-center">
            <div className="col-md-10 offset-md-1 col-lg-6 offset-lg-6 mt-md-n9 text-center text-lg-start">
              <h1
                className="display-4 mb-4 px-md-10 px-lg-0"
                data-cue="slideInDown"
                data-group="download"
                data-show="true"
              >
                Get all your finances tracked in one app.
              </h1>
              <p
                className="lead fs-lg mb-7 px-md-10 px-lg-0 pe-xxl-15"
                data-cue="slideInDown"
                data-group="download"
                data-show="true"
              >
                Sahi Savings is now available to download from Google Play
                Store.
              </p>
              <div className="d-flex justify-content-center justify-content-lg-start">
                <Fade right>
                  <span>
                    <a
                      target={"_blank"}
                      href="https://play.google.com/store/apps/details?id=com.sahisavings"
                      className="btn btn-green btn-icon btn-icon-start rounded"
                    >
                      <RiGooglePlayLine
                        style={{ fontSize: "20px", marginRight: "10px" }}
                      />{" "}
                      Google Play
                    </a>
                  </span>
                </Fade>
              </div>
            </div>
            {/* <!-- /column --> */}
          </div>
          {/* <!-- /.row --> */}
        </div>
        {/* <!-- /.container --> */}
      </section>
    </>
  );
};

export default Home;
