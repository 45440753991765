import React from "react";

const Sectionchildrenallowance = () => {
  return (
    <div
      style={{
        padding: "10%",
        justifyContent: "center",
        textAlign: "justify",
      }}
    >
      <p>
        The employer may provide you education allowance for your children as
        part of your salary. Such allowance received by the employer towards
        children education is exempt from tax. </p>
        <p>
        However, the employee can claim
        maximum Rs. 100 per month as exemption or Rs. 1200 per annum. The
        exemption is allowed for a maximum of 2 children.
      </p>
    </div>
  );
};

export default Sectionchildrenallowance;
