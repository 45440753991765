import React from "react";

const Sectionbooksperiodicals = () => {
  return (
    <div
      style={{
        padding: "10%",
        justifyContent: "center",
        textAlign: "justify",
      }}
    >
      <p>
      Employees can claim reimbursement of expenses incurred on books, newspaper
      subscription, periodicals, journals and so on. 
      These reimbursements are tax-free for employees. 
      
      The maximum amount of reimbursement is limited to:
      <li>Actual bill amount or</li> 
      <li>Amount provided for books and periodicals in CTC</li>
      Whichever is lower.
      </p>
    </div>
  );
};

export default Sectionbooksperiodicals;
