import React from "react";

const Section80U = () => {
  return (
    <div
      style={{
        padding: "10%",
        justifyContent: "center",
        textAlign: "justify",
      }}
    >
      <p>
        <p>
          <b>What is Section 80U?</b>{" "}
        </p>
        Section 80U offers tax benefits if an individual suffers a disability,
        while Section 80DD offers tax benefits if an individual taxpayer&rsquo;s
        dependent family member(s) suffers from a disability. This article is
        centered around discussing the tax benefits available under Section 80U.
      </p>

      <p>
        <p>
          <b>Who can claim deduction under Section 80U?</b>
        </p>
        A resident individual who has been certified as a person with a
        disability by the medical authority can claim the tax benefit under
        Section 80U. For the purpose of this section, a person with a disability
        is defined as a person who has at least 40 percent disability, certified
        by the medical authorities.
      </p>

      <p>
        <li>Blindness</li> 
        <li>Low vision</li> 
        <li>Leprosy-cured</li>{" "}
        <li>Hearing impairment</li> 
        <li>Loco motor disability</li>{" "}
        <li>Mental retardation</li> 
        <li>Mental illness</li>
      </p>

      <p>
        The section also provides a definition for a severe disability which
        refers to a condition where the disability is 80 percent or more. Severe
        disability also includes multiple disabilities, autism and cerebral
        palsy.
      </p>

      <p>
      <p><b>What is the maximum amount of Deduction allowed under 80U?</b></p> A
        deduction of Rs. 75,000 is allowed for people with disabilities, and Rs.
        1,25,000 deduction for people with severe disability.
      </p>

      <p>
      <p><b>How to claim deductions under Section 80U?</b></p> 
      
      <li>Apart from the
        certificate certifying the disability from a recognized medical
        authority in Form 10-IA no other documents are required. There&rsquo;s
        no need of producing bills for the cost incurred for the pursuance of
        treatment or such other expenses.</li>
        <li>For making the claim under this section, one must submit the medical
        certificate indicating the disability together with the income tax
        returns as per Section 139 for the relevant AY. In case the disability
        assessment certificate has expired, one would still be able to claim
        such deductions in the year in which the certificate expires. However, a
        fresh certificate would be required from the succeeding year for
        claiming the benefits u/s 80U.</li>
        <li>Certificates could be obtained from the medical authorities who could be
        either, a neurologist having a degree of Doctor of Medicine (MD) in
        Neurology (in case of children, a pediatric neurologist having an
        equivalent degree) or a civil surgeon or Chief Medical Officer in a
        government hospital.</li>
      </p>
      <p>
        Note: If the disability is temporary and requires reassessment after a
        certain period, then the certificate&rsquo;s validity starts from the
        assessment year relevant to the financial year during which it was
        issued and ends during the assessment year relevant to the financial
        year when the certificate expires.
      </p>

      <p>
      <p><b>Difference between Section 80U and Section 80DD?</b></p> 
      
      <li>Section 80DD
        provides tax deductions to the family members and the kin of the
        taxpayer with a disability, whereas Section 80U provides deductions to
        the individual taxpayer with a disability himself.</li>
        <li> Section 80DD is applicable if a taxpayer deposits a specified amount as
        an insurance premium for taking care of his/her dependent disabled
        person. Under section 80DD, the deduction limits are the same as Section
        80U. Here, a dependent refers to the siblings of the assessee, parents,
        spouse, children or a member of a Hindu Unified Family.</li>
      </p>
    </div>
  );
};

export default Section80U;
