import React from "react";
import { NavLink, Link } from "react-router-dom";
import {
  IoHomeOutline,
  IoChatboxOutline,
  IoInformationCircleOutline,
} from "react-icons/io5";
import { IoIosArrowDown } from "react-icons/io";
import { GrServices } from "react-icons/gr";
import bbpsLogo from '../Extra/bbpslogo.svg';
const Nave = () => {
  return (
    <>
      <header
        className="wrapper bg-soft-primary"
        style={{ position: "fixed", width: "100%", top: "0px", zIndex: "9999" }}
      >
        <nav className="navbar navbar-expand-lg classNameic transparent navbar-light">
          <div className="container flex-lg-row flex-nowrap align-items-center">
            <div className="navbar-brand w-100">
              <NavLink to="/">
                <img src="img/logo.png" alt="" style={{ width: "250px" }} />
              </NavLink>
            </div>
            <div className="navbar-collapse offcanvas offcanvas-nav offcanvas-start">
              <div className="offcanvas-header d-lg-none">
                <button
                  type="button"
                  className="btn-close btn-close-white"
                  data-bs-dismiss="offcanvas"
                  aria-label="Close"
                ></button>
              </div>
              <div className="offcanvas-body ms-lg-auto d-flex flex-column h-100">
                <ul className="navbar-nav">
                  <li className="nav-item">
                    <NavLink className="nav-link" to="/">
                      Home
                    </NavLink>
                  </li>
                  <li className="nav-item dropdown">
                    <a href="#" className="nav-link">
                      Invest <IoIosArrowDown />
                    </a>
                    <ul className="dropdown-menu">
                      <li className="nav-item">
                        <a
                          href="https://www.assetplus.in/mfd/ARN-190825"
                          className="nav-link"
                          style={{ padding: "5px 20px" }}
                        >
                          Mutual Funds
                        </a>
                      </li>
                      <li className="nav-item dropdown">
                        <a
                          className="nav-link"
                          href=""
                          style={{ padding: "5px 20px" }}
                        >
                          National Pension Scheme <IoIosArrowDown />
                        </a>
                        <ul className="dropdown-menu">
                          <li className="nav-item">
                            <a
                              href="/Npsinvestment"
                              className="nav-link"
                              style={{ padding: "5px 20px" }}
                            >
                              New Registration
                            </a>
                          </li>
                          <li className="nav-item">
                            <a
                              href="/Npssip"
                              className="nav-link"
                              style={{ padding: "5px 20px" }}
                            >
                              Contribution
                            </a>
                          </li>
                        </ul>
                      </li>
                      <li className="nav-item dropdown">
                        <a
                          className="nav-link"
                          href="#"
                          style={{ padding: "5px 20px" }}
                        >
                          Deposit <IoIosArrowDown />
                        </a>
                        <ul
                          className="dropdown-menu"
                          style={{ padding: "5px 20px" }}
                        >
                          <li className="nav-item">
                            <a
                              target="_blank"
                              className="nav-link"
                              href="https://www.bajajfinserv.in/fixed-deposit-application-form?utm_source=133223"
                              style={{ padding: "5px 20px" }}
                            >
                              Bajaj Finance Fixed Deposit
                            </a>
                          </li>
                          <li className="nav-item">
                            <a
                              target="_blank"
                              href="#"
                              className="nav-link"
                              style={{ padding: "5px 20px" }}
                            >
                              PNB Housing Finance Fixed Deposit
                            </a>
                          </li>
                          <li className="nav-item">
                            <a
                              target="_blank"
                              href="#"
                              className="nav-link"
                              style={{ padding: "5px 20px" }}
                            >
                              HDFC Ltd Fixed Deposit
                            </a>
                          </li>
                        </ul>
                      </li>
                    </ul>
                  </li>

                  <li className="nav-item dropdown">
                    <NavLink className="nav-link" to="/shulkpay">
                    Bill Pay
                    </NavLink>
                  </li>
                                    
                  <li className="nav-item ">
                    <NavLink className="nav-link" to="/services">
                      Services
                    </NavLink>
                  </li>
                  
                  <li className="nav-item">
                    <NavLink className="nav-link" to="https://sahisavings.digitap.work/login">
                      Apply
                    </NavLink>
                  </li>
                </ul>
              </div>
            </div>
            {/* <div className="navbar-other ms-lg-4">
            <ul className="navbar-nav flex-row align-items-center ms-auto">
              <li className="nav-item d-none d-md-block">
                 
              </li>
              <li className="nav-item d-lg-none">
                <button className="hamburger offcanvas-nav-btn"><span></span></button>
              </li>
            </ul>
          </div> */}
          </div>
        </nav>
      </header>
      <div className="d-lg-none">
        <ul
          className="d-flex m-0 p-0"
          style={{
            justifyContent: "space-evenly",
            position: "fixed",
            bottom: "0",
            zIndex: "999999",
            boxShadow: "0px 1px 5px #959595",
            background: " #fff",
            width: "100vw",
            listStyle: "none",
          }}
        >
          <li className="nav-item text-center">
            <NavLink className="nav-link p-1" to="/">
              <IoHomeOutline />
              <br />
              <small>Home</small>
            </NavLink>
          </li>
          <li className="nav-item text-center">
            <NavLink className="nav-link p-1" to="/about">
              <IoInformationCircleOutline />
              <br />
              <small>About</small>
            </NavLink>
          </li>
          <li className="nav-item text-center">
            <NavLink className="nav-link p-1" to="/services">
              <GrServices />
              <br />
              <small>Services</small>
            </NavLink>
          </li>
          <li className="nav-item text-center">
            <NavLink className="nav-link p-1" to="/shulkpay">
            <img src={bbpsLogo} alt="BBPS Logo" style={{ width: '16px', height: '16px' }} />
              <br />
              <small>Bill Pay</small>
            </NavLink>
          </li>
          <li className="nav-item text-center">
            <NavLink className="nav-link p-1" to="/contact">
              <IoChatboxOutline />
              <br />
              <small>Contact</small>
            </NavLink>
          </li>
        </ul>
      </div>
    </>
  );
};

export default Nave;
