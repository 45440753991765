import React from "react";

const Section80CCD1B = () => {
  return (
    <div
      style={{
        padding: "10%",
        justifyContent: "center",
        textAlign: "justify",
      }}
    >
      <p>
      <b>Exclusive Tax Benefit to all NPS Subscribers u/s 80CCD (1B)</b>
        </p>
        <p> An additional deduction for investment up to Rs. 50,000 in NPS (Tier I
        account) is available exclusively to NPS subscribers under subsection
        80CCD (1B) ; this also includes investments made towards Atal Pension
        Yojana (APY). This is over and above the deduction of Rs. 150,000
        available under section 80C of Income Tax Act. 1961. </p>
      
      <p>
        <b>Things to know while claiming deductions under Section 80CCD(1B){" "}</b>
        <li>
          The additional deduction of Rs. 50,000/- is available only for
          contributions made to NPS Tier 1 accounts. Tier 2 accounts are not
          eligible to claim the deduction under Section 80CCD(1B)
        </li>{" "}
        <li>
          The deductions under Section 80CCD(1B) are available to salaried
          individuals as well as to self-employed individuals.
        </li>{" "}
        <li>
          Individuals need to share a statement of transaction as proof of
          investment in NPS or APY.
        </li>{" "}
        <li>
          Partial withdrawals are allowed under NPS but are subject to specific
          terms and conditions
        </li>{" "}
        <li>
          The total exemption limit under Section 80CCD(1B) is Rs. 50,000/- and
          is independent of exemptions under Sec 80C. Hence, you can claim a
          maximum deduction of Rs. 2,00,000/-
        </li>{" "}
        <li>
          In case the assessee dies, and the nominee decides to close the NPS
          account, then the amount received by the nominee is exempt from
          taxation.
        </li>{" "}
        <li>
          If partial withdrawals are made from the account, then only 25% of the
          contribution made is exempt from taxation.
        </li>{" "}
        <li>
          If the assessee is an employee and decides to close the NPS account or
          opt out of NPS, then only 40% of the total amount is tax-exempt.
        </li>{" "}
        <li>
          The assessee can withdraw 60% of the entire amount on reaching the age
          of 60 years as tax-free income. The remaining 40% is also tax-free if
          it is used to purchase an annuity plan.{" "}
        </li>
      </p>

      <p>
        Section 80CCD(1B) offers you an excellent opportunity to save a
        substantial amount on your taxation liabilities. This way you can not
        only reduce your present tax liabilities but also work towards creating
        a substantial corpus for your retirement. Do keep in mind the points
        mentioned above, before taking any action related to your NPS account.
      </p>
    </div>
  );
};

export default Section80CCD1B;
