import React from "react";
import "./Exemptions.scss";
import { NavLink, Link } from "react-router-dom";
import sectionbooksandperiodicals from "./sectionbooksandperiodicals.svg";
import sectionchildrenallowance from "./sectionchildrenallowance.svg";
import sectionhra from "./sectionhra.svg";
import sectionlta from "./sectionlta.svg";
import sectionmobilereimburse from "./sectionmobilereimburse.svg";
import sectionfoodcoupons from "./sectionfoodcoupons.svg";

const Exemptions = () => {
  const data = [
    {
      url: "/Sectionhra",
      icon: sectionhra,
      title: "House Rent Allowance (HRA)",
    },
    {
      url: "/Sectionlta",
      icon: sectionlta,
      title: "Leave Travel Allowance (LTA)",
    },
    {
      url: "/Sectionfoodcoupons",
      icon: sectionfoodcoupons,
      title: "Food Coupons",
    },
    {
      url: "/Sectionmobilereimburse",
      icon: sectionmobilereimburse,
      title: "Mobile Reimbursement",
    },
    {
      url: "/Sectionbooksperiodicals",
      icon: sectionbooksandperiodicals,
      title: "Books and Periodicals",
    },
    {
      url: "/Sectionchildrenallowance",
      icon: sectionchildrenallowance,
      title: "Children Allowances",
    },
    ];
  return (
    <div>
      <div className="Exemptions">
        <div className="cardgrid">
          {data.map((n) => (
            <>
              <NavLink to={n.url}>
                <div className="cardx">
                  <img src={n.icon} alt="" />
                  <h2>{n.title}</h2>
                </div>
              </NavLink>
            </>
          ))}
        </div>
      </div>
    </div>
  );
};

export default Exemptions;
