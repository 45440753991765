import React from "react";
import Fade from "react-reveal/Fade";
import { AiFillCaretRight } from "react-icons/ai";
import About from "../data/About";

const Aboutsection = () => {
  return (
    <>
      <section className="bg-light angled upper-end lower-end">
        <div className="container pt-20">
          <div className="row gx-lg-8 gx-xl-12 gy-10 mb-14 mb-md-17 align-items-center">
            <div className="col-lg-6 position-relative order-lg-2">
              <div
                className="shape bg-dot primary rellax w-16 h-20"
                style={{
                  top: "13rem",
                  left: "5.5rem",
                  transform: "translate3d(0px, 48px, 0px)",
                }}
              ></div>
              <div className="overlap-grid overlap-grid-2">
                <div className="item">
                  <figure className="rounded shadow">
                    <img src="img/photos/about2.webp" alt="" />
                  </figure>
                </div>
                {/* <div className="item">
                    <figure className="rounded shadow">
                      <img src="img/photos/about3.jpg" alt="" />
                    </figure>
                  </div> */}
              </div>
            </div>
            {/* <!--/column --> */}
            <div className="col-lg-6">
              <img
                src="img/icons/lineal/megaphone.svg"
                className="icon-svg icon-svg-md mb-4"
                alt=""
              />
              <Fade left>
                <h2 className="display-4 mb-3">Who Are We?</h2>
                <p className="lead fs-lg">
                  We are personal finance management company that believes in
                  solving problem that affects Salaried Individual the most
                  i.e., Taxes!
                </p>
              </Fade>
              <div className="gx-xl-8">
                <div className="">
                  <Fade left>
                    <ul className="icon-list bullet-bg bullet-soft-primary mb-0">
                      {About.map((aboutdat) => {
                        return (
                          <li className="mt-3">
                            <span>
                              <AiFillCaretRight
                                style={{
                                  position: "absolute",
                                  left: "8px",
                                  top: "6px",
                                }}
                              />
                            </span>
                            <span>{aboutdat.dis}</span>
                          </li>
                        );
                      })}
                    </ul>
                  </Fade>
                </div>
                {/* <!--/column --> */}
              </div>
              {/* <!--/.row --> */}
            </div>
            {/* <!--/column --> */}
          </div>
          {/* <!--/.row --> */}
        </div>
        {/* <!-- /.container --> */}
      </section>
    </>
  );
};

export default Aboutsection;
