import React from 'react';
import Aboutsection from '../Components/Aboutsection';
import Breadcrumb from '../Components/Breadcrumb';

const About = () => {
  return (
    <>
      {/* <Breadcrumb title={'About Us'}/> */}
      <Aboutsection />
    </>
  );
};

export default About;
