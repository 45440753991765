import React from "react";

const Section80G = () => {
  return (
    <div
      style={{
        padding: "10%",
        justifyContent: "center",
        textAlign: "justify",
      }}
    >
      <p>
        <p>
          <b>What is Section 80G?</b>{" "}
        </p>
        Section 80G of the Indian Income Tax Act allows you a tax deduction on
        donations made to a charitable organisation. Not all donations would be
        eligible for deductions under this Section. Only donations made to
        prescribed funds qualify as a deduction. This deduction can be claimed
        by any taxpayer &ndash; individuals, companies, firms or any other
        person. This deduction is not available if you have opted for the new
        tax regime.
      </p>

      <p>
        <p>
          <b>How to claim deduction under this Section?</b>
        </p>{" "}
        This deduction can only be claimed when the contribution is made via a
        cheque, draft, or cash. In-kind contributions such as food, material,
        clothes, medicines etc., do not qualify for deduction under Section 80G.
      </p>

      <p>
        <p>
          <b>Amendment to Section 80G: </b>
        </p>{" "}
        From financial year 2017-18 onwards, any donations made in cash
        exceeding Rs 2,000 will not be considered as a deduction. The donations
        above Rs 2,000 should be made in any mode other than cash to qualify
        under Section 80G. Previously, the limit of donation in cash was Rs
        10,000.
      </p>

      <p>
        <p>
          <b>Eligible Donation Amount: </b>
        </p>{" "}
        The various donations specified in Section 80G are eligible for a
        deduction of up to 100% or 50% with or without restriction, as provided
        in Section 80G.
      </p>

      <p>
        <b>
          To be able to claim deduction, the following details have to be
          submitted in your income tax return:
        </b>
      </p>

      <p>
        <li>Name of the donee</li>
        <li>PAN of the donee</li> <li>Address of the donee</li>{" "}
        <li>
          Amount of contribution &ndash; the breakup of contribution in cash and
          another mode
        </li>{" "}
        <li>The amount eligible for deduction</li>
      </p>

      <p>
        <ul>
          <b>
            1. Donations eligible for 100% deduction without qualifying limit{" "}
          </b>
        </ul>
        <li>National Defence Fund set up by the Central Government</li>{" "}
        <li>Prime Minister&rsquo;s National Relief Fund</li>{" "}
        <li>National Foundation for Communal Harmony</li>{" "}
        <li>
          An approved university/educational institution of National eminence
        </li>{" "}
        <li>
          Zila Saksharta Samiti constituted in any district under the
          chairmanship of the Collector of that district
        </li>{" "}
        <li>
          Fund set up by a state government for the medical relief to the poor
        </li>{" "}
        <li>National Illness Assistance Fund</li>{" "}
        <li>
          National Blood Transfusion Council or to any State Blood Transfusion
          Council
        </li>{" "}
        <li>
          National Trust for Welfare of Persons with Autism, Cerebral Palsy,
          Mental Retardation, and Multiple Disabilities
        </li>{" "}
        <li>National Sports Fund</li> <li>National Cultural Fund</li>{" "}
        <li>Fund for Technology Development and Application</li>{" "}
        <li>National Children&rsquo;s Fund</li>{" "}
        <li>
          Chief Minister&rsquo;s Relief Fund or Lieutenant Governor&rsquo;s
          Relief Fund with respect to any State or Union Territory
        </li>{" "}
        <li>
          The Army Central Welfare Fund or the Indian Naval Benevolent Fund or
          the Air Force Central Welfare Fund, Andhra Pradesh Chief
          Minister&rsquo;s Cyclone Relief Fund, 1996
        </li>{" "}
        <li>
          The Maharashtra Chief Minister&rsquo;s Relief Fund during October 1,
          1993, and October 6, 1993
        </li>{" "}
        <li>Chief Minister&rsquo;s Earthquake Relief Fund, Maharashtra</li>{" "}
        <li>
          Any fund set up by the State Government of Gujarat exclusively for
          providing relief to the victims of the earthquake in Gujarat
        </li>{" "}
        <li>
          Any trust, institution or fund to which Section 80G(5C) applies for
          providing relief to the victims of the earthquake in Gujarat
          (contribution made during January 26, 2001, and September 30, 2001)
        </li>{" "}
        <li>Prime Minister&rsquo;s Armenia Earthquake Relief Fund</li>{" "}
        <li>Africa (Public Contributions &ndash; India) Fund</li>{" "}
        <li>Swachh Bharat Kosh (applicable from FY 2014-15)</li>{" "}
        <li>Clean Ganga Fund (applicable from FY 2014-15)</li>{" "}
        <li>
          National Fund for Control of Drug Abuse (applicable from FY 2015-16)
        </li>{" "}
        Donations eligible for 50% deduction without qualifying limit{" "}
        <li>Jawaharlal Nehru Memorial Fund</li>{" "}
        <li>Prime Minister&rsquo;s Drought Relief Fund</li>{" "}
        <li>Indira Gandhi Memorial Trust</li> <li>Rajiv Gandhi Foundation</li>{" "}
      </p>
      <p>
        <ul>
          <b>
            2. Donations eligible for 100% deduction subject to 10% of adjusted
            gross total income{" "}
          </b>
        </ul>
        <li>
          Donations to the government or any approved local authority,
          institution or association to be utilised to promote family planning
        </li>{" "}
        <li>
          Donation by a company to the Indian Olympic Association or any other
          notified association or institution established in India to develop
          infrastructure for sports and games in India or sponsor sports and
          games in India.
        </li>{" "}
      </p>
      <p>
        <p>
          <ul>
            <b>
              3. Donations eligible for 50% deduction subject to 10% of adjusted
              gross total income{" "}
            </b>
          </ul>
        </p>
        <li>
          Any other fund or institution satisfies the conditions mentioned in
          Section 80G(5).
        </li>{" "}
        <li>
          Government or any local authority, to be utilised for any charitable
          purpose other than promoting family planning.
        </li>{" "}
        <li>
          Any authority constituted in India to deal with and satisfy the need
          for housing accommodation or the purpose of planning, development or
          improvement of cities, towns, villages or both.
        </li>{" "}
        <li>
          Any corporation referred to in Section 10(26BB) for promoting the
          interest of the minority community.
        </li>{" "}
        <li>
          For repairs or renovation of any notified temple, mosque, gurudwara,
          church, or other places.
        </li>
      </p>
      <p>
        <ul><b>4. Donations eligible for 50% deduction subject to 10% of adjusted gross
        total income{" "}</b></ul>
        <li>
          Any other fund or institution satisfies the conditions mentioned in
          Section 80G(5).
        </li>{" "}
        <li>
          Government or any local authority, to be utilised for any charitable
          purpose other than promoting family planning.
        </li>{" "}
        <li>
          Any authority constituted in India to deal with and satisfy the need
          for housing accommodation or the purpose of planning, development or
          improvement of cities, towns, villages or both.
        </li>{" "}
        <li>
          Any corporation referred to in Section 10(26BB) for promoting the
          interest of the minority community.
        </li>{" "}
        <li>
          For repairs or renovation of any notified temple, mosque, gurudwara,
          church, or other places.
        </li>
      </p>
    </div>
  );
};

export default Section80G;
