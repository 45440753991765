import React from 'react';
import Nave from './Nave';
import Footer from './Footer';
import { Outlet } from 'react-router-dom';

const Header = () => {
  return (
    <>
      <Nave />
      <Outlet />
      <Footer/>
    </>
  )
}

export default Header;