import React from "react";

const sectionfoodcoupons = () => {
  return (
    <div
      style={{
        padding: "10%",
        justifyContent: "center",
        textAlign: "justify",
      }}
    >
      <p>
      Many employers provide food coupons to their employees as perquisites.
      These food coupons are exempt from tax up to Rs 50 per meal. </p>
      <p>So, if the
      whole month is considered based on 22 working days and 2 meals a day, the
      monthly benefit will be Rs 2,200 (Rs 50*22*2). </p>
      <p>
      Employees can avail this
      tax benefit and claim Rs 26,400 under tax-free food coupons in a financial
      year.</p>
      
    </div>
  );
};

export default sectionfoodcoupons;
