import React from "react";

const Section80TTA = () => {
  return (
    <div
      style={{
        padding: "10%",
        justifyContent: "center",
        textAlign: "justify",
      }}
    >
      <p>
        <b>What is Section 80TTA? </b> 
        <p>Most of us will have atleast one savings
        bank account but most of us are not aware that the interest received is
        taxable under the head &lsquo;Income from other sources. However, you
        can save taxes on interest received up to Rs 10,000. Section 80TTA of
        the Income Tax Act, 1961 provides a deduction of Rs 10,000 on such
        interest income.</p>
      </p>

      <p>
        <b>Who can Claim 80TTA Deduction?</b> 
        <p>Section 80TTA deduction is
        available to an Individual and HUF.</p>
      </p>

      <p>
        <b>
          Which Type of Interest Incomes are Allowed/Disallowed as Deduction
          Under Section 80TTA?
        </b>
      </p>

      <p>
        <b>Interest Income allowed as Deduction under Section 80TTA:</b>
      </p>

      <p>
        <li>From a savings account with a bank</li>{" "}
        <li>
          From a savings account with a co-operative society carrying on the
          business of banking
        </li>{" "}
        <li>From a savings account with a post office</li>
      </p>

      <p>
        <b>Interest Income Not Allowed as Deduction Under Section 80TTA:</b>{" "}
        <li>Interest from fixed deposits</li>{" "}
        <li>Interest from recurring deposits</li>{" "}
        <li>Any other time deposits</li>{" "}
        <li>
          Time deposits mean deposits repayable on the expiry of fixed periods.
        </li>
      </p>

      <p>
        <b>
          What is the maximum amount of deduction allowed under Section 80TTA?
        </b>{" "}
        <p>The maximum deduction is limited to Rs 10,000. If your interest income
        is less than Rs 10,000, the entire interest income will be your
        deduction. If your interest income is more than Rs 10,000, your
        deduction shall be limited to Rs 10,000. (You have to consider your
        total interest income from all banks in case you have multiple
        accounts).</p>
      </p>

      <p>
        <b>How to Claim Deduction under Section 80TTA?</b> 
        <p>Add your total
        interest income under the head &lsquo;Income from Other Sources&rsquo;
        in your return. Calculate your gross total income for the financial year
        from all the income sources and then show it as a deduction under
        Section 80TTA.</p>
      </p>
    </div>
  );
};

export default Section80TTA;
