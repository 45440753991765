import React, { useRef, useState, } from "react";
import { TailSpin } from "react-loading-icons";
import { ToastContainer } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";

const Shulkpay = () => {
    const [url, setUrl]=useState('https://billpayment.shulkpay.com/pwa?auth=eyJhbGciOiJIUzI1NiIsInR5cCI6IkpXVCJ9.eyJwd2EiOiIzZDJhODczOTNhYzJiZDgyZDkwMDg0YTQ5YWUyNzM5NDBhNTlkM2E1YWRmM2E3Yjk0OWJiMzZlYjBkNDA5ZmVmNDhiMjdkY2E4MmU1MTIzMGUxZWMyZmRjZWM4NTAyZjUwNDk3ZGUxOWU1OWQzYzIyNjEzMDQwNzI1NzU3OGMzNyIsImlhdCI6MTUxNjIzOTAyMn0.9etJp6oAy041nIfz0RZD8sPlW1Dynq87mA5avOsQg3k&mob=');
  const [phone, setPhone]=useState('');
  const form = useRef();

  // retrived data state
  const [data, setData]=useState(false);
 
  // submit event
  const handleSubmit=(e)=>{
    setData(true);
    e.preventDefault();
    // console.log(url+phone);
    window.location.replace(url+phone);
  
  }

  return (
    <>
    <section className="wrapper" style={{background: "#175783"}}>
      <div className="container pb-14 py-md-15 mt-14">
        <div className="row">
          <div className="col-xl-10 mx-auto">
            <div className="row justify-content-center">
              <div className="col-lg-5">
                <div className="p-2 mt-5 " style={{  background: "#ffff", boxShadow: "0px 3px 10px"}}>
                <div>
                <img
                src="img/bharatbillpaylogo.png"
                className="img-fluid mt-2 mx-auto d-flex w-50"
                alt=""
              />
                </div>
                <div>
                   <h2 className="text-center my-5 mt-0">Login to  Pay Your Bills</h2>
                </div>
                <form
                  className="contact-form needs-validation col-mt-116 mt-5"
                  method=""
                  ref={form}
                  // onSubmit={sendEmail}
                  onSubmit={handleSubmit}
                >
                  <div className="messages"></div>
                  <div className="row gx-4">
                    <div className="col-md-12">
                      <div className="form-floating mb-4 mt-10 mb-13 px-3">
                        <input
                          id="form_email"
                          type="tel"
                          name="phone"
                          className="form-control my-5 pl-2"
                          placeholder="Enter your mobile number"
                          onChange={(e)=>setPhone(e.target.value)}
                          value={phone}
                          minlength={10}
                          style={{boxShadow: "-1px 3px 5px"}}
                          required
                        />
                        <label htmlFor="form_email" style={{marginLeft: "12px"}}>Enter your mobile number *</label>
                        <div className="valid-feedback"> Looks good! </div>
                        <div className="invalid-feedback">
                          {" "}
                          Please provide a valid mobile number.{" "}
                        </div>
                      </div>
                    </div>
                    <div className="col-12 mb-10">
                      <button
                        type="submit"
                        className="btn btn-primary btn-send mb-3 d-flex mx-auto"
                        style={{ width: "150px", background: "#195984", borderColor: "#195984" }}
                        disabled={data ? "" : ""}>
                        {data ? <TailSpin /> : "LOGIN"}
                      </button>
                    </div>
                  </div>
                  <ToastContainer />
                </form>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </section>
  </>
  )
}

export default Shulkpay