import React from "react";
import Fade from "react-reveal/Fade";
import Servicedata from "../data/Servicedata";
import Serviccard from "./Serviccard";

const Servicesec = (props) => {
  return (
    <>
      <section className="wrapper bg-soft-primary">
        <div className="container py-14 py-md-5">
          <div className="row text-center mt-xl-12">
            <div className="col-md-10 offset-md-1 col-lg-8 offset-lg-2">
              <h2 className="fs-15 text-uppercase text-muted mb-3">
                App Features
              </h2>
              <h3 className="display-4 mb-9 px-xxl-11">
                Sahi Savings is the only app a Salaried Individuals need to save
                Taxes!
              </h3>
            </div>
            <div className="row gx-lg-8 gx-xl-12 gy-8 mb-14 mb-md-17">
              {Servicedata.map((val) => {
                return (
                  <div className="col-md-6 col-lg-4" key={val.id}>
                    <Serviccard
                      src={val.src}
                      title={val.title}
                      description={val.description}
                    />
                  </div>
                );
              })}
            </div>
          </div>
        </div>
      </section>

      <section className={props.bg}>
        <div className="container py-14 py-md-5">
          <div className={props.height}>
            <div className="col-lg-6">
              <Fade left>
                <figure>
                  <img src="img/Rectangle.webp" data-cue="fadeIn" alt="" />
                </figure>
              </Fade>
            </div>
            <div className="col-lg-6">
              <h2 className="fs-15 text-uppercase text-muted mb-3">
                How It Works
              </h2>
              {/* <h3 className="display-4 mb-5">
               
                </h3> */}
              <p className="mb-8">
                Download the app, sign up and enter your investment details to
                track, mention your tax slab & tax regime and voila, you’re all
                set.
              </p>
              <div
                className="row gy-6 gx-xxl-8 process-wrapper"
                data-cues="slideInUp"
                data-group="process"
                data-disabled="true"
              >
                <div
                  className="col-md-5"
                  data-cue="slideInUp"
                  data-group="process"
                >
                  <Fade right>
                    <img
                      src="img/icons/lineal/cloud-computing.svg"
                      className="icon-svg icon-svg-sm text-green mb-3"
                      alt=""
                      style={{ width: "2.2rem", height: "2.2rem" }}
                    />
                    <h4 className="mb-1">1. Download</h4>
                    <p className="mb-0">
                      Download the app from Google Play Store
                    </p>
                  </Fade>
                </div>
                <div
                  className="col-md-7"
                  data-cue="slideInUp"
                  data-group="process"
                >
                  <Fade right>
                    <img
                      src="img/icons/lineal/smartphone-2.svg"
                      className="icon-svg icon-svg-sm text-red mb-3"
                      alt=""
                      style={{ width: "2.2rem", height: "2.2rem" }}
                    />
                    <h4 className="mb-1">2. Sign Up</h4>
                    <p className="mb-0">
                      Enter your basic details, set your passcode & login
                    </p>
                  </Fade>
                </div>
                <div
                  className="col-md-12"
                  data-cue="slideInUp"
                  data-group="process"
                >
                  <Fade right>
                    <img
                      src="img/icons/lineal/rocket.svg"
                      className="icon-svg icon-svg-sm text-aqua mb-3"
                      alt=""
                      style={{ width: "2.2rem", height: "2.2rem" }}
                    />
                    <h4 className="mb-1">3. Start Tracking</h4>
                    <p className="mb-0">
                      Enter all your investment, insurance & loan details.
                      Specify your tax slab / taxable income & tax regime.
                    </p>
                  </Fade>
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>
    </>
  );
};

export default Servicesec;
