import React from "react";
import "./Exemptions.scss";
import { NavLink, Link } from "react-router-dom";
import icontest from "./deductionicon.svg";
const Deductions = () => {
  const data = [
    {
      url: "/section80c",
      icon: icontest,
      title: "Section 80C",
    },
    {
      url: "/section80ccd1b",
      icon: icontest,
      title: "Section 80CCD1B",
    },
    {
      url: "/section80d",
      icon: icontest,
      title: "Section 80D",
    },
    {
      url: "/section80dd",
      icon: icontest,
      title: "Section 80DD",
    },
    {
      url: "/section80ddb",
      icon: icontest,
      title: "Section 80DDB",
    },
    {
      url: "/section80e",
      icon: icontest,
      title: "Section 80E",
    },
    {
      url: "/section80ee",
      icon: icontest,
      title: "Section 80EE",
    },
    {
      url: "/section80eea",
      icon: icontest,
      title: "Section 80EEA",
    },
    {
      url: "/section80eeb",
      icon: icontest,
      title: "Section 80EEB",
    },
    {
      url: "/section24",
      icon: icontest,
      title: "Section 24",
    },
    {
      url: "/section80g",
      icon: icontest,
      title: "Section 80G",
    },
    {
      url: "/section80gg",
      icon: icontest,
      title: "Section 80GG",
    },
    {
      url: "/section80gga",
      icon: icontest,
      title: "Section 80GGA",
    },
    {
      url: "/section80ggc",
      icon: icontest,
      title: "Section 80GGC",
    },
    {
      url: "/section80tta",
      icon: icontest,
      title: "Section 80TTA",
    },
    {
      url: "/section80u",
      icon: icontest,
      title: "Section 80U",
    },
  ];
  return (
    <div>
      <div className="Exemptions">
        <div className="cardgrid">
          {data.map((n) => (
            <>
              <NavLink to={n.url}>
                <div className="cardx">
                  <img src={n.icon} alt="" />
                  <h2>{n.title}</h2>
                </div>
              </NavLink>
            </>
          ))}
        </div>
      </div>
    </div>
  );
};

export default Deductions;
