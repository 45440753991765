import React from "react";

const Section80EEA = () => {
  return (
    <div
      style={{
        padding: "10%",
        justifyContent: "center",
        textAlign: "justify",
      }}
    >
      <p><b>What is Section EEA?</b></p> 
      <p>
        Under the objective &ldquo;Housing for all&rdquo;, the government has
        now extended the interest deduction allowed for low-cost housing loans
        taken during the period between 1 April 2019 and 31 March 2022.
      </p>

      <p>
        Accordingly, a new Section 80EEA has been included allowing an interest
        deduction from AY 2020-21 (FY 2019-20). The older provision of Section
        80EE allowed a deduction up to Rs 50,000 for interest paid by first-time
        home-buyers for loans sanctioned from a financial institution between 1
        April 2016 and 31 March 2017.
      </p>

      <p>
        To benefit and give impetus to the real estate sector, the government
        has extended the benefit for FY 2019-20. This deduction can be claimed
        until you have repaid the housing loan.
      </p>

      <p>
        <p><b>What is the Eligibility criteria?</b></p>
        <li>The deduction under this
        section is available only to individuals.</li>
        <li>This deduction is not
        available to any other taxpayer. Hence, if you are a HUF, AOP,
        partnership firm, company, or any other kind of taxpayer, you cannot
        claim any benefit under this section.</li>
      </p>

      <p>
        <p><b>What is the maximum Amount of deduction allowed?</b></p>
        <li>A deduction for interest payments up to Rs 1,50,000
        is available under Section 80EEA. This deduction is over and above the
        deduction of Rs 2 lakh for interest payments available under Section
        24 of the Income Tax Act. </li>
        <li>Hence, the taxpayers can claim a total
        deduction of Rs 3.5L for interest on home loan, if they meet the
        conditions of section 80EEA.</li>
      </p>

      <p>
      <p><b>Additional conditions</b></p> 
        Similar to Section 80EE, in order to claim deduction
        under Section 80EEA, you should not own any other house property on the
        date of the sanction of a loan.
      
      <li>Housing loan must be taken from a
        financial institution or a housing finance company for buying a
        residential house property.</li>
        <li>The loan should be sanctioned during the
        period 1st April 2019 and 31st March 2022.</li> 
        <li> Stamp duty value of the house
        property should be Rs 45 lakhs or less.</li>
        <li>The individual taxpayer should
        not be eligible to claim deduction under the existing Section 80EE. The
        taxpayer should be a first-time home buyer. </li>
        <li>The taxpayer should not own
        any residential house property as on the date of sanction of the loan.</li>
      </p>

      <p>
        <b>Conditions with respect to the carpet area of the house property:</b>
      </p>

      <p>
        <li>The carpet area of the house property should not exceed 60 square meter
        (645 sq ft) in metropolitan cities of Bengaluru, Chennai, Delhi
        National Capital Region (limited to Delhi, Noida, Greater Noida,
        Ghaziabad, Gurgaon, Faridabad), Hyderabad, Kolkata and Mumbai (whole of
        Mumbai Metropolitan Region).</li>
        <li>Carpet area should not exceed 90 square
        meter (968 sq ft) in any other cities or towns.</li> 
        <li>Further, this definition
        will be effective for affordable real estate projects approved on or
        after 1 September 2019.</li>
        <li>Section 80EEA has been introduced to further
        extend the benefits allowed under Section 80EE for low-cost housing.
        Earlier, Section 80EE had been amended from time to time to allow a
        deduction for interest paid on housing loan for the FY 2013-14, FY
        2014-15, and FY 2016-17.</li>
      </p>

      <p>
        The section does not specify if you need to be a Resident to
        claim this benefit. Therefore, it can be concluded that both Resident
        and Non-Resident Indians can claim this deduction.
      </p>

      <p>
        The section also does not specify if the residential house should be
        self-occupied to claim the deduction. So, borrowers living in rented
        houses can also claim this deduction. Moreover, the deduction can only
        be claimed by individuals for the house purchases jointly or singly. If
        a person jointly owns the house with a spouse and they both are paying
        the instalments of the loan, then both of them can claim this deduction.
        However, they must meet all the conditions laid down.
      </p>

      <p>
      <p><b>Difference between Section 80EEA and Section 24</b></p>
        <li>Under Section 24,
        homeowners can claim a deduction for interest payments up to Rs 2 lakh
        on their home loan, if the owner or his family resides in the house
        property. The deduction of up to Rs 2 Lakh applies even when the house
        is vacant. If you have rented out the property, the entire home loan
        interest is allowed as a deduction.</li>
      
        <li>If you satisfy the conditions of both Section 24 and Section 80EEA of
        the Income Tax Act, you can claim the benefits under both the sections.</li>
      
      <li>First, exhaust your deductible limit under Section 24, which is Rs 2
        lakh. Then, go on to claim the additional benefits under Section 80EEA.
        Therefore, this deduction is in addition to the Rs 2 lakh limit allowed
        under Section 24.</li>
      </p>
    </div>
  );
};

export default Section80EEA;
