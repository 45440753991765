import React from "react";

const Section80C = () => {
  return (
    <div
      style={{
        padding: "10%",
        justifyContent: "center",
        textAlign: "justify",
      }}
    >
      <p>
        <p>
          <b>What is Section 80C?</b>
        </p>
        Income tax department with a view to encourage savings and investments
        amongst the taxpayers have provided various deductions from the taxable
        income under chapter VI A deductions. 80C being the most famous, there
        are other deductions as well which are beneficial for the taxpayers to
        reduce their tax liability by investing in them.
      </p>

      <p>
        Deductions on Investments under Section 80C is one of the most popular
        and favourite sections amongst the taxpayers as it allows to reduce
        taxable income by making tax saving investments or incur eligible
        expenses. It allows a maximum deduction of Rs 150,000 every year from
        the taxpayers total income. The benefit of this deduction can be availed
        by Individuals and HUFs. Companies, partnership firms, LLPs are not
        allowed to avail benefit of this deduction.
      </p>

      <p>
        <b>
          Below are the investment / tax savings options available under Section
        </b>
        80C <li> Life insurance premium</li>
        <li> Equity Linked Savings Scheme (ELSS)</li>
        <li> Employee Provident Fund (EPF)</li>
        <li> Annuity / Pension Schemes</li>
        <li> Principal payment on home loans</li>
        <li> Tuition fees for children</li>
        <li> Contribution to PPF Account</li>
        <li> Sukanya Samriddhi Account</li>
        <li> NSC (National Saving Certificate)</li>
        <li> Fixed Deposit (Tax Savings)</li>
        <li> Post office time deposits</li>
        <li> National Pension Scheme</li>
      </p>

      <p>
        Section 80C also includes subsections which are 80CCC, 80CCD (1) , 80CCD
        (1b) and 80CCD (2).
      </p>

      <p>
        <b>
          It is important to note that the overall limit including the
          subsections for claiming deduction is Rs 150,000 except an additional
          deduction of Rs 50,000 allowed u/s 80CCD(1b).
        </b>
      </p>

      <p>
        <b>80CCC Deduction for life insurance annuity plan: </b>
        80CCC allows deduction for payment towards annuity pension plans Pension
        received from the annuity or amount received upon surrender of the
        annuity, including interest or bonus accrued on the annuity, is taxable
        in the year of receipt.
      </p>

      <p>
        <b>80CCD (1) Deduction for NPS Employee&rsquo;s contribution: </b>
        Maximum deduction allowed is least of the following 10% of salary (in
        case taxpayer is employee) 20% of gross total income (in case of self
        employed) Rs 1.5 Lakh (limit allowed u/s 80C)
      </p>

      <p>
        <b>80CCD (1B)Deduction for NPS/APY: </b> Additional deduction of Rs
        50,000 is allowed for amount deposited to NPS account Contributions to
        Atal Pension Yojana is also eligible for deduction.
      </p>

      <p>
        <b>80CCD (2) Deduction for NPS Employers contribution: </b> Deduction
        for Employers contribution is allowed for deduction upto 10% of basic
        salary plus dearness allowance under this section. Benefit in this
        section is allowed only to salaried individuals and not self employed.
      </p>
    </div>
  );
};

export default Section80C;
