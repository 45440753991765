import React from "react";

const Section80GGA = () => {
  return (
    <div
      style={{
        padding: "10%",
        justifyContent: "center",
        textAlign: "justify",
      }}
    >
      <p>
        <p><b>What is Section 80GGA?</b> </p>
        Section 80GGA allows deductions for
        donations made towards scientific research or rural development. This
        deduction is allowed to all assessees except those who have an income
        (or loss) from a business and/or a profession.
      </p>

      <p>
        <b>How to claim deduction under this section?</b>
      </p>

      <p>
        <li>
          Donations can be made in the form of a cheque, a draft, or cash.{" "}
        </li>{" "}
        <li>
          However, cash donations over Rs 2,000 are not allowed as deductions.
          100% of the amount donated or contributed is eligible for deductions.
        </li>
      </p>

      <p>
        <b>Donations eligible under Section 80GGA</b>{" "}
        <li>
          Any sum paid to a research association that undertakes scientific
          research, or a sum paid to a college, university, or any other
          institution to be used for scientific research that is all approved by
          the prescribed authority under Section 35(1)(ii)
        </li>{" "}
        <li>
          Sum paid to a research association which undertakes research in social
          science or statistical research, or sum paid to a college, university,
          or any other institution to be used for the same purpose, and these
          must all be approved by the prescribed authority under Section
          35(1)(iii)
        </li>{" "}
        <li>
          Sum paid to an approved association or institution which undertakes
          any programme of rural development and is approved under Section 35CCA
        </li>{" "}
        <li>
          Sum paid to an approved association or institution which undertakes
          training of person(s) for implementing programmes of rural development
        </li>{" "}
        <li>
          Sum paid to a public sector company, local authority or an approved
          association or institution which carries out projects or schemes
          approved under Section 35AC.
        </li>{" "}
        <li>Sum paid to notified Rural Development Fund</li>{" "}
        <li>Sum paid to notified Fund for Afforestation</li>{" "}
        <li>Sum paid to notified National Poverty Eradication Fund</li>{" "}
        <li>
          If a deduction has been allowed under Section 80GGA, such expenses
          shall not be deductible under any other provision of the Income Tax
          Act.
        </li>
      </p>
    </div>
  );
};

export default Section80GGA;
