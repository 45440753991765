import React from "react";
import Testicard from "../Components/Testicard";
import Testimonialdata from "../data/Testimonialdata";

const Testimonial = () => {
  return (
    <>
      <section className="wrapper bg-light">
        <div className="container py-14 py-md-17">
          <div className="row mt-md-n25">
            <div className="col-md-10 offset-md-1 col-lg-8 offset-lg-2 mx-auto text-center">
              <h2 className="fs-15 text-uppercase text-muted mb-3">
                Happy Customers
              </h2>
              <h3 className="display-4 mb-10 px-xl-10 px-xxl-15">
                Don't take our word for it. See what customers are saying about
                us.
              </h3>
            </div>
          </div>
          <div className="grid">
            <div className="row isotope gy-6">
              {Testimonialdata.map((testi) => {
                return (
                  <div className="item col-md-7 col-xl-4" key={testi.id}>
                    <Testicard
                      img={testi.img}
                      id={testi.id}
                      name={testi.name}
                      subtitle={testi.subtitle}
                      revew={testi.revew}
                    />
                  </div>
                );
              })}
            </div>
          </div>
        </div>
      </section>
    </>
  );
};

export default Testimonial;
