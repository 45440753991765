import React from "react";

const Sectionmobilereimburse = () => {
  return (
    <div
      style={{
        padding: "10%",
        justifyContent: "center",
        textAlign: "justify",
      }}
    >
      <p>
        A taxpayer may incur expenses on mobile and telephone used at residence.
        The income tax law allows an employee to claim a tax free reimbursement
        of expenses incurred. </p>
        <p>
        An employee can claim reimbursement of the actual
        bill amount paid or amount provided in the salary package, whichever is
        lower.
      </p>
    </div>
  );
};

export default Sectionmobilereimburse;
