import React from "react";

const Section24 = () => {
  return (
    <div
      style={{
        padding: "10%",
        justifyContent: "center",
        textAlign: "justify",
      }}
    >
      <p>
        <b>What is Section 24?</b>
      </p>
      <p>
        Section 24 considers the interest on a home loan for a tax deduction. It
        also includes &quot;deductions from income from house property.&quot;
        There is no compulsion to reside in a house against which an individual
        claims tax deduction.
      </p>

      <p>
        <b>
          Following are the categories which are considered as income arising
          from housing property:
        </b>
      </p>

      <p>
        <li>
          If an individual rents a house, the rental income is considered.
        </li>
        <li>
          In case an individual owns more than one house, the Net Annual Value
          of all housing property is his or her income except one where he or
          she resides. If an individual owns a house and resides in that house
          only, the income estimated from that housing property is zero.{" "}
        </li>
        <li>
          So, note that income arising from the annual value of additional
          housing property and rental income is taxable after being subjected to
          deductions under Section 24 of the Income Tax Act.
        </li>
      </p>

      <p>
        Borrowers can enjoy income tax deductions of up to &#8377; 2,00,000, on
        the interest paid towards a home loan. This is applicable for owners
        residing with their family in that house against which they borrowed the
        home loan. This is also valid for vacant houses. If an individual rents
        a house, the entire interest of a housing loan qualifies for an income
        tax deduction.
      </p>

      <p>
        <b>
          Conditions to Claim Deduction on Home Loan Interest? To claim a
          deduction of up to &#8377; 2,00,000, individuals need to meet the
          following conditions:
        </b>
      </p>

      <p>
        <li>
          Individuals borrowed a home loan on or after 1st April 1999 to
          construct or purchase a housing property.{" "}
        </li>
        <li>
          The house must be acquired or constructed within 5 years (it was 3
          years till FY 2015-2016) from the completion of a financial year in
          which an individual borrowed this loan.{" "}
        </li>
        <li>
          {" "}
          Assesse should own an interest certificate for the interest payable
          towards the borrowed fund.
        </li>
      </p>

      <p>
        <b>
          This deduction limit on the home loan interest can be limited to
          &#8377; 30,000 under the following circumstances:
        </b>
      </p>

      <p>
        <li>
          If a candidate fails to meet the above conditions, he or she can claim
          a tax deduction of &#8377; 30,000.{" "}
        </li>
        <li>
          {" "}
          Individuals borrowed a loan before 1st April 1999 to repair,
          reconstruct their house, or purchase or construct a new home.{" "}
        </li>
        <li>
          Borrowers obtained the loan on or after 1st April 1999 to repair,
          reconstruct their house or renovate existing housing property.
        </li>
      </p>

      <p>
        <b>
          Exceptional Situations Under Section 24 Here are some of the
          exceptional rules under Section 24 of the Income Tax Act:
        </b>
      </p>

      <p>
        <li>If owners do not occupy a housing property, they can claim an income tax
        deduction without an upper limit on the overall interest paid to the
        home loan.</li> 
        <li>If individuals do not occupy a house and reside in a rented
        property in other cities due to their employment or business purpose,
        they can claim a tax deduction of up to &#8377; 2,00,000 on interest
        paid to a home loan. </li> 
        <li>There is no rebate on home loan interest under
        Section 24 for expenses incurred on brokerage to arrange a tenant or
        additional charges payable towards a loan. </li> 
        <li>When individuals borrow a
        home loan to construct or purchase a house, they can claim a deduction
        on the interest payable towards a home loan while the housing property
        is under construction.</li> 
        <p></p>
        <p>
          However, this is not applicable if an individual uses these funds
          sanctioned under a home loan to repair or reconstruct an existing
          house. The maximum deduction limit in this scenario is not up to
          &#8377; 2,00,000.
        </p>
        <p>
          This deduction will be distributed in 5 equal instalments for every
          financial year. Individuals will receive the first instalment in the
          year of completion of construction or purchase of a house.
        </p>
      </p>
    </div>
  );
};

export default Section24;
