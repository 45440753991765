import React from "react";

const Section80EEB = () => {
  return (
    <div
      style={{
        padding: "10%",
        justifyContent: "center",
        textAlign: "justify",
      }}
    >
      <p>
        <p>
          {" "}
          <p>
            <b>What is Section 80EEB?</b>
          </p>{" "}
          In the union budget 2019, the government has announced an incentive
          for purchasing electric vehicle. In the budget speech, the finance
          minister has stated that advanced battery and registered e-vehicles
          will be incentivised under the scheme. A new section 80EEB has been
          introduced allowing a deduction for interest paid on loan taken for
          the purchase of electric vehicles from the AY 2020-21.
        </p>
      </p>

      <p>
        <p>
          {" "}
          <p>
            <b>Who is eligible for claiming deduction under this section?</b>
          </p>{" "}
          The deduction under this section is available only to individuals.
          This deduction is not available to any other taxpayer. Thus, if you
          are a HUF, AOP, Partnership firm, a company, or any other kind of
          taxpayer, you cannot claim any benefit under this section.
        </p>{" "}
        <p>
          {" "}
          <p>
            <b>What is the maximum Amount of deduction allowed?</b>
          </p>{" "}
          <li>
            A deduction for interest payments up to Rs 1,50,000 is available
            under Section 80EEB. An individual taxpayer may have an electric
            vehicle either for personal or business use. This deduction would
            facilitate individuals having an electric vehicle for personal use
            to claim the interest paid on the vehicle loan.
          </li>{" "}
          <li>
            Do note that an individual taxpayer should obtain the interest paid
            certificate and keep the necessary documents such as tax invoice and
            loan documents handy at the time of filing the return.
          </li>
        </p>
      </p>

      <p>
        <p>
          <b>What are the conditions for claiming the deduction?</b>
        </p>{" "}
        <li>
          The loan must be availed from a financial institution or a non-banking
          financial company for buying an electric vehicle.
        </li>{" "}
        <li>
          The loan must be sanctioned anytime during the period starting from 1
          April 2019 till 31 March 2023.
        </li>{" "}
        <li>
          &ldquo;Electric vehicle&rdquo; has been defined to mean a vehicle
          which is powered exclusively by an electric motor whose traction
          energy is supplied exclusively by traction battery installed in the
          vehicle and has such electric regenerative braking system, which
          during braking provides for the conversion of vehicle kinetic energy
          into electrical energy.
        </li>
      </p>
    </div>
  );
};

export default Section80EEB;
