const Compani = [
  {
    id: "1",
    src: "img/brands/01nse.png",
  },
  {
    id: "2",
    src: "img/brands/02tjsb.png",
  },
  {
    id: "3",
    src: "img/brands/5.svg",
  },
  {
    id: "4",
    src: "img/brands/03mfu.png",
  },
  {
    id: "5",
    src: "img/brands/04bse.png",
  },
  // {
  //     id : '6',
  //     src : 'img/brands/c6.png',
  // },
];
export default Compani;
