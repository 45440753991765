import React from "react";
import Nave from "./Nave";
import Footer from "./Footer";
import { Outlet } from "react-router-dom";

const HeaderWithoutFooter = () => {
  return (
    <>
      <Nave />
      <Outlet />
    </>
  );
};

export default HeaderWithoutFooter;
