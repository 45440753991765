import React from 'react';
import Fade from 'react-reveal/Fade';

const Serviccard = (props) => {
  return (
    <>
              <Fade bottom>
                <div style={{textAlign:"left"}}>
                  <div>
                    <img
                      src={props.src}
                      className="icon-svg icon-svg-sm text-aqua me-4"
                      alt=""
                    />
                  </div>
                  <div>
                    <h4 className="mb-1">{props.title}</h4>
                    <p className="mb-0">{props.description}</p>
                  </div>
                </div>
              </Fade>
    </>
  )
}

export default Serviccard;