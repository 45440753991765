import React from 'react';

const Termscondition = () => {
  return (
    <>
      <section className="bg-light angled upper-end lower-end">
        <div className="container pt-20">
          <h2 className="mb-5 text-center" style={{ color: '#205d88' }}>
            TERMS & CONDITION 
          </h2>
          <p>
            The mobile application ‘Sahi Savings’ (hereinafter referred to as
            “Application”) is owned and operated by ‘ K2VS Finance and
            Investment Private Limited’ a company incorporated under the
            Companies Act, 2013 with its registered office at Flat No 15 B Wing
            Andheri Indradarshan Convent Avenue Road Four Bungalows Andheri West
            Mumbai 400053 Maharashtra India
          </p>
          <br />
          <p>
            K2VS Finance and Investment Private Limited (“Sahi Savings”) is in
            the business of distribution of financial products through its
            mobile technology platform. Sahi Savings requests you to carefully
            go through these terms &amp; conditions (“Terms &amp; Conditions”)
            to avail the access to the distribution network of Sahi Savings
            through its mobile technology platform. If you continue to browse
            and use this Application, you irrevocably and unconditionally are
            agreeing to comply with, abide by and be bound by all the
            obligations as stipulated in the following terms and conditions of
            use, which together with our privacy policy and any other applicable
            policies which are incorporated herein by way of reference or
            available by hyperlink, shall govern Sahi Savings’ relationship with
            you in relation to this application. These Terms &amp; Conditions
            supersede all previous oral and written terms and conditions (if
            any) communicated to you and shall act as an Agreement between Sahi
            Savings and Users.
          </p>
          <p>
            <strong>
              THE USE OF THIS APPLICATION IS SUBJECT TO THE FOLLOWING Terms
              &amp; Conditions:
            </strong>
          </p>
          <p>Definitions</p>
          <p>
            “<strong>User</strong>“, “<strong>You/you</strong>“, “
            <strong>Your/your</strong>” means any person or user or Registered
            user or viewer or Registered Customer who views, browses, accesses
            or uses our Sahi Savings.
          </p>
          <p>
            “<strong>Use</strong>” or “<strong>Using</strong>” means to browse,
            access, view, copy, availing Services, or other benefit from using
            the Application.
          </p>
          <p>
            “<strong>Sahi Savings</strong>” or “<strong>us</strong>” or “
            <strong>we</strong>” or “<strong>our</strong>” shall mean and refer
            to K2VS Finance and Investment Private Limited and its affiliates,
            officers, employees, agents, partners, and licensors.
          </p>
          <p>
            “<strong>AMC</strong>” shall mean and include Asset Management
            Company listed on BSE.
          </p>
          <p>
            “<strong>BSE</strong>” shall mean Bombay Stock Exchange.
          </p>
          <p>
            “<strong>Services</strong>” means all the services as provided in
            Terms &amp; Conditions from time to time.
          </p>
          <p>
            “<strong>Force Majeure Event</strong>” means any event due to any
            cause beyond the reasonable control of Sahi Savings, including
            without limitations, unavailability of any communication systems,
            breach, or virus in the processes or payment or delivery mechanism,
            sabotage, fire, flood, explosion, acts of god, civil commotion,
            strikes or industrial action of any kind, riots, insurrection, war,
            acts of government, computer hacking, unauthorised access to
            computer data and storage devices, computer crashes, malfunctioning
            in the computer terminal or the systems getting affected by any
            malicious, destructive or corrupting code or program, mechanical or
            technical errors/failures or power shut down, faults or failures in
            telecommunication etc.
          </p>
          <p>
            “<strong>Application</strong>” or “
            <strong>Mobile Application</strong>” means Sahi Savings, the mobile
            Application accessed through or downloaded from the Play store or
            App store i.e., Android or IOS.
          </p>
          <p>
            “<strong>RTA</strong>” shall mean Registrar and Transfer Agent.
          </p>
          <p>
            “<strong>Registered User</strong>” or “
            <strong>Registered Customer</strong>” shall mean and include the
            users or customers who have Investment Account with Sahi Savings to
            avail the Services or any part thereof, as provided on the
            Application.
          </p>
          <p>
            “<strong>SIP</strong>” shall mean Systematic Investment Plans
          </p>
          <p>
            “<strong>STP</strong>” shall mean Systematic Transfer Plans
          </p>
          <p>
            “<strong>NAV</strong>” shall mean Net Asset Value
          </p>
          <p>
            “<strong>Account</strong>” shall mean the account opened with Sahi
            Savings by the Registered Users, for use of the Application or for
            making investments through the Application.
          </p>
          <p>
            <strong>Application use:</strong>
          </p>
          <p>
            User acknowledges that in accessing and using the Services offered
            by Sahi Savings, user may utilize the content and Services offered
            on the Sahi Savings application. User agrees to follow the terms and
            conditions for the use of the Application as may be specified by
            Sahi Savings from time to time. Notwithstanding such acceptance of
            the Sahi Savings usage terms, User acknowledges and agrees to the
            following, that:
          </p>
          <p>
            Login Credentials should be kept safe and secure to prevent
            unauthorized access to your Sahi Savings account. If you think that
            the security of your Sahi Savings account has been compromised,
            change your passcode and contact us immediately for further
            assistance, on the following e-mail: support @sahisavings.com
          </p>
          <br />
          <p>
            – Sahi Savings (K2VS Finance and Investment Private Limited) is an
            AMFI registered distributor and advisor of mutual funds (ARN –
            114376) and enables Users to purchase units of various mutual funds,
            details of which shall be made available on the Application, from
            time to time.
          </p>
          <p>
            – The investment account of the user will be activated after Sahi
            Savings completes the verification process on the personal
            information provided at the time of enrolment and in accordance with
            the Know Your Client (“KYC”) guidelines issued by the Securities and
            Exchange Board of India (“SEBI”).
          </p>
          <p>
            – User shall not to copy, reproduce, sell, redistribute, publish,
            enter into a database, display, perform, modify, transmit, license,
            create derivatives from, transfer or in any way exploit any part of
            any information, content, materials, services available from or
            through the Application, except that which user may download for
            personal, non‐commercial use.
          </p>
          <p>
            – User will not use Sahi Savings’ applications for any purpose that
            is unlawful or prohibited by these Terms. User also agrees that user
            will not use the Applications in any manner that could damage,
            disable, or impair the Application or interfere with any other
            person’s use of Sahi Savings’ applications.
          </p>
          <p>
            – The software and hardware underlying the Applications as well as
            other internet related software which are required for accessing the
            Applications are the legal property of the respective vendors. The
            permission given by Sahi Savings to access the application will not
            convey any proprietary or ownership rights in the above software/
            hardware. Users agree that user shall not attempt to modify,
            translate, disassemble, decompile or reverse engineer the
            software/hardware underlying the Application or create any
            derivative product based on the software / hardware.
          </p>
          <p>
            – Sahi Savings is not responsible for the availability of content or
            other services on third party sites linked from the Sahi Savings
            applications. User agrees and is aware that access of hyperlinks to
            other internet sites are at users own risk and the content,
            accuracy, opinions expressed, and other links provided by these
            sites are not verified, monitored, or endorsed by Sahi Savings in
            any way. Sahi Savings does not make any warranties and expressly
            disclaims all warranties express or implied, including without
            limitation, those of merchantability and fitness for a particular
            purpose, title or non-infringement with respect to any information
            or services or products that are available or advertised or sold
            through these third-party websites.
          </p>
          <p>
            – Sahi Savings has the absolute discretion to amend or supplement
            any of the Sahi Savings Application Terms at any time without prior
            notice for such changes. Any such amendment shall be effective
            immediately. It shall be Users responsibility to review these Terms
            and Conditions periodically for any updates/changes. User agrees
            that the information and assistance provided on the Sahi Savings
            Application are true to the knowledge of Sahi Savings and should not
            be construed as investment advice, and that Sahi Savings shall have
            no liability for any discrepancy in any such information or
            assistance.
          </p>
          <p>
            – Mere use of the Application, shall be construed as your intent to
            contract with us and shall constitute your binding obligations, with
            us.
          </p>
          <p>
            – Users understand that it is users responsibility to check the Sahi
            Savings Application’s Terms carefully before accessing or using or
            transacting on our Application.
          </p>
          <p>
            – As long as User complies with these Terms and Conditions, we grant
            user a personal, non-exclusive, non-transferable, limited privilege
            to enter and use the Application. User acknowledges and understands
            that use of the Application shall be at your own risk and has the
            discretion of not using the Application.
          </p>
          <p>
            – Sahi Savings shall not be liable if any transaction does not
            fructify or may not be completed or for any failure on part of the
            Application to perform any of its obligations under these terms and
            conditions or those applicable specifically to its
            services/facilities if performance is prevented, hindered, or
            delayed by a Force Majeure event and in such case its obligations
            shall be suspended for so long as the Force Majeure event continues.
          </p>
          <p>
            – All information submitted by user to Sahi Savings (except for the
            personal information which shall be by virtue of its nature, be
            deemed to be proprietary information of the User) whether through
            the Sahi Savings Applications or otherwise shall be deemed to be the
            property of Sahi Savings, and Sahi Savings shall be free to use any
            ideas, concepts, know-how or techniques provided by such users at
            the Sahi Savings application, in any manner whatsoever.
          </p>
          <p>
            – Not all the products and services offered on Application are
            available in all geographic areas and user may not be eligible for
            all the products or services offered by Sahi Savings on the
            Application. Sahi Savings reserves the right to determine the
            availability and eligibility for any product or service.
          </p>
          <p>
            <strong>AVAILING OF SERVICES</strong>
          </p>
          <p>
            Service(s) shall be availed in accordance with the Terms and
            Conditions mentioned herein. User agree that Sahi Savings and / or
            the mutual fund may at its sole discretion vary the terms and
            conditions or withdraw any of the facilities provided herein from
            time to time. Further information is elaborated under “Termination
            of Service”
          </p>
          <p>
            User hereby irrevocably and unconditionally grants no objection to
            Sahi Savings and the respective mutual funds / RTAs to collate the
            transaction details relating to the investments in mutual fund units
            done by user on the online technology platform of Sahi Savings and
            provide such transaction data to Sahi Savings for further processing
            of user transactions.
          </p>
          <p>
            <strong>REGISTRATION OF THE USER</strong>
          </p>
          <p>
            Access to Sahi Savings’ Services will be granted by Sahi Savings
            only to Registered Customers of Sahi Savings, post the enrolment for
            the Services. User agrees and acknowledges that the Services
            provided herein are presently available in respect of select Mutual
            Funds only with whom Sahi Savings has entered into a separate
            arrangement. User shall provide the required details and the
            documents for the purpose of the registration and enrolment. For the
            purpose of registering on the Application:
          </p>
          <p>
            A valid phone number has to be provided and a password has to be
            created.
          </p>
          <p>
            For the purpose of enrolment and using the Services of Sahi Savings,
            the User has to provide the following details:
          </p>
          <p>Pan Card details and a copy of the PAN card</p>
          <p>Bank Account Details and a cancelled cheque leaf</p>
          <p>Address proof</p>
          <p>Photograph</p>
          <p>Signature</p>
          <p>
            The above provided information/details shall be uploaded by the User
            in soft copy form on the Application at the time of enrolment. Once
            the information is received by Sahi Savings, the executive of Sahi
            Savings shall co-ordinate with the User, for obtaining an
            attestation from the Registered Users, if required. Upon completion
            of the attestation requirements, the documents shall be provided to
            the concerned regulatory authority/mutual fund company for the
            purpose of opening an account with Sahi Savings for the transaction
            to be made by the Users.
          </p>
          <p>
            During the registration process, the customer will be prompted to
            sign on the mobile screen. Sahi Savings will capture the signature
            of the customer and will utilize only for the purpose of completing
            the registration formalities – namely, KYC form and BSE Account
            Opening Form. The signature does not allow Sahi Savings to undertake
            any transactions other than specified here.
          </p>
          <p>
            <strong>Kindly Note</strong>: In the event, if any discrepancy is
            found, in any of the information provided by the user, for example,
            information in any of the documents provided does not match with the
            adjoining documents, such mismatch shall lead to the rejection of
            the Documents. The privacy of the documents provided or any such
            personally identifiable information provided to Sahi Savings, shall
            be maintained in accordance to the privacy policy of the
            Application.
          </p>
          <p>
            User agree that any and / or all information that may be provided by
            you to Sahi Savings from time to time, including but not limited to
            contact information, address and tax related information or any
            other information required under existing or future KYC or other
            norms and laws, may be shared by Sahi Savings with the regulatory
            authorities/mutual funds/BSE/AMCs or their respective authorized
            service providers, auditors, legal and tax consultants in compliance
            with the extant legal provisions from time to time.
          </p>
          <p>
            There may be an exit load applicable to certain mutual fund schemes
            which is mentioned in the respective offer documents including
            Scheme Information Document (SID) /Key Information Memorandum (KIM)
            and addendums issued thereto from time to time (collectively
            referred to as “scheme related documents”). User shall read all the
            scheme related documents before making any transaction on Sahi
            Savings.
          </p>
          <p>
            Investments from persons from the country other than India may not
            be permitted in certain mutual funds. Sahi Savings/ BSE/their
            respective trustee companies shall not be liable for rejection of an
            application by mutual funds, where the investor is a person from a
            country other than India or such other prohibited investor as may be
            specified in the respective scheme related documents from time to
            time.
          </p>
          <p>
            Purchase requests made through Sahi Savings’ online technology
            platform shall be processed by the respective mutual funds only
            after funds sufficient to cover the purchase price and other costs
            and charges have been received by the respective mutual funds.
            Payments towards the purchase price and other costs and charges
            shall be made by the Registered Customers through NEFT or the
            payment gateway or other electronic means made available by Sahi
            Savings on the online technology platform. If for any reason the
            mutual fund is unable to allow a transaction for purchase or
            redemption of the full quantity of units such as transacted by a
            Registered Customer through the online technology platform of Sahi
            Savings, the respective mutual fund shall be entitled to process a
            lesser quantity of units being purchased or redeemed (as the case
            may be) by such Registered Customer. In such a case, Sahi Savings
            shall be not responsible for the non-execution of transactions for
            the entire quantity or the remaining quantity of units.
          </p>
          <p>
            Transaction rights for SIPs and STPs will be pursuant to the
            Registered Customer executing the instructions, in the manner and
            form as prescribed by Sahi Savings and / or mutual funds on the
            online technology platform from time to time. The instructions will
            be applicable to all SIP, STP transactions whether presently
            existing or to be opened in future. User agrees that, upon granting
            such instructions for SIP, STP transactions, user will be bound by
            the terms of the relevant SIP and STP scheme of the mutual fund to
            which user subscribes.
          </p>
          <p>
            User agrees and acknowledges that any transaction done or purported
            to be done by user on a business day would be processed on the next
            business day. The user will be allotted Mutual Fund units by the
            respective AMC based on the NAV applicable of that business day.
            This will be applicable to switch and redemption transactions also.
          </p>
          <p>
            While Sahi Savings shall make every effort to ensure adherence to
            the above timelines, Sahi Savings shall in no way be responsible or
            held liable for any delay thereof, including for loss of interest
            and / or opportunity loss and / or any loss arising due to movement
            in NAV or any other losses, liabilities, damages, costs, charges,
            expenses which the investor(s) may sustain, incur or suffer or be
            put to or become liable or incur directly or indirectly by reason or
            as a consequence of any delay thereof.
          </p>
          <p>
            User acknowledge that the units of the scheme shall be allotted,
            redeemed or switched, as the case may be, by the respective mutual
            funds at the applicable NAV of the concerned mutual fund scheme as
            provided by mutual funds and consistent with the terms of the
            Scheme. However, Sahi Savings shall not be liable for any loss that
            may arise to user as a result of incorrect NAV applied on units
            allotted to user by the mutual fund.
          </p>
          <p>
            User acknowledges that any transactions done on a holiday would be
            processed on the next business day and the NAV would be applicable
            as per the respective scheme related documents.
          </p>
          <p>
            User acknowledges that transactions once placed cannot be cancelled.
            However, user can withdraw money by redeeming the mutual fund units.
            Upon such withdrawal, the settlement amount towards the mutual fund
            or the units of Mutual fund shall be credited to the registered
            account of the user within 3-4 bank working days.
          </p>
          <p>
            User acknowledges that any directions pertaining to all transactions
            including withdrawal, STP and switch transactions provided by the
            User from its Registered User account shall be deemed to be
            considered as the bona-fide order placed by the User.
          </p>
          <p>
            User have read and understood the contents of the Scheme related
            Document and the details of the scheme and they have not received or
            been induced by any rebate or gifts, directly or indirectly, in
            making investment. User hereby declare that the amount invested/to
            be invested by their investment in the scheme(s) of Mutual Fund (s)
            is derived through legitimate sources and is not held or designed
            for the purpose of contravention of any act, rules, regulations or
            any statute or legislation or any other applicable laws or any
            notifications, directions issued by any governmental or statutory
            authority from time to time.
          </p>
          <p>
            The AMFI Registration Number (“ARN”) holder has disclosed to user
            all the commissions (in the form of trail commission or any other
            mode), payable to ARN holder for the different competing Schemes of
            various Mutual Funds from amongst which the Scheme is being
            recommended to users.
          </p>
          <p>
            <strong>LINK TO THIRD PARTY</strong>
          </p>
          <p>
            Sahi Savings may provide you links to third party services (“Third
            Party Services”). You agree to use the Services at your sole risk
            and that we shall not have any liability to you for content that may
            be found to be offensive, indecent, or objectionable. User
            understand that certain Services may display, include or make
            available content, data, information, applications or materials from
            third parties (“Third Party Materials”) or provide links to certain
            third party websites. By using the Third Party Services, you
            acknowledge and agree that Sahi Savings is not responsible for
            examining or evaluating the content, accuracy, completeness,
            timeliness, validity, copyright compliance, legality, decency,
            quality or any other aspect of such Third Party Materials or
            websites. SAHI SAVINGS DOES NOT WARRANT OR ENDORSE AND DOES NOT
            ASSUME AND WILL NOT HAVE ANY LIABILITY OR RESPONSIBILITY TO YOU OR
            ANY OTHER PERSON FOR ANY THIRD PARTY SERVICES, THIRD PARTY MATERIALS
            OR WEB SITES, OR FOR ANY OTHER MATERIALS, PRODUCTS, OR SERVICES OF
            THIRD PARTIES.
          </p>
          <p>
            <strong>NO OBEJECTION FROM THE USER(S)</strong>
          </p>
          <p>
            User acknowledge and grants no objection to Sahi Savings to do or
            place transaction requests for the User on the online technology
            platform of Sahi Savings and at users own risk and cost, all or any
            of the following acts, deeds matters and things:
          </p>
          <p>
            To forward user transaction data and requests to the respective
            mutual funds/BSE with respect to the instructions and orders of
            sale, purchase, transfer, splitting and consolidation of mutual
            funds units.
          </p>
          <p>
            To transmit to the mutual fund and/or the BSE, information relating
            to user nomination/changes in investment plan/ any other changes
            made through the online platform.
          </p>
          <p>
            To obtain and forward to the BSE/AMC, its Authorized Registrar
            user’s information as available in the KYC records, including user
            signature(s). User agrees that such records may be used by the
            BSE/AMC/Authorised Registrar for authorizing the transactions that
            may be submitted by user in physical/offline mode.
          </p>
          <p>
            To contact user regarding matters pertaining to the operation of
            user account including, but not limited to, servicing of users
            requests, communication of special offers, new product launches and
            product recommendations. This consent will override any registration
            for Do Not Call (“DNC”) / National Do Not Call (“NDNC”).
          </p>
          <p>
            <strong>Holding pattern and Customer details</strong>
          </p>
          <p>
            User acknowledge and agree that the Sahi Savings holding pattern of
            the Registered Customer, as reflected in the Sahi Savings system, is
            deemed to be the Sahi Savings account holding pattern of the account
            holder/investor(s).
          </p>
          <p>
            User also agrees there will be no changes allowed in the holding
            pattern of the investors associated with the Account once the
            Account is activated.
          </p>
          <p>
            User agrees that once an investment is made using the holding
            pattern, User will not be allowed to change the holding pattern for
            the subscriptions.
          </p>
          <p>
            The other requisite data (which is not part of the form) required to
            be reported to BSE / authorized registrar of the AMC will be
            extracted from Sahi Savings account details maintained for the First
            holder. The mode of holding in all customer joint accounts is
            treated as “Anyone or Survivor”.
          </p>
          <p>
            <strong>Account Statement</strong>
          </p>
          <p>
            User agree and acknowledges that it shall be the obligation of the
            AMC and not of Sahi Savings to regularly send to the Registered
            Customer such communications as required under the SEBI Regulations.
          </p>
          <p>
            <strong>Fees and Charges</strong>
          </p>
          <p>
            While currently, the Services offered by Sahi Savings are free of
            transaction charges, user acknowledges that Sahi Savings reserves
            the right to charge fees in the future for the Services or any other
            services offered by Sahi Savings. Any such changes will be
            communicated by Sahi Savings via email 30 days ahead of
            implementation, and will be displayed on the Sahi Savings
            Application. Under such circumstances, user has the option to
            transfer its assets out of its Account without incurring any costs
            payable to Sahi Savings.
          </p>
          <p>
            User agree and understand that any such charges/fees that Sahi
            Savings may choose to levy will be in addition to those levied by
            the mutual funds, if any. All fees, charges and reimbursement of
            expenditure shall be paid or made in full by user without any
            counter claim, set off or withholding. All liabilities and
            obligations of the Registered Customer(s) hereunder to Sahi
            Savings/mutual fund/AMCs/trustees of the AMC shall be joint and/or
            several, as the case may be.
          </p>
          <p>
            <strong>LIABILITY OF SAHI SAVINGS</strong>
          </p>
          <p>
            Sahi Savings shall not in absence of gross negligence on the part of
            user, be liable to user for any act, omission or delay by the mutual
            fund or for any claims which user may suffer or incur as a result of
            or in course or discharge by Sahi Savings or its employees,
            officers, directors, nominee or agent of Sahi Savings’ duties.
          </p>
          <p>
            Without prejudice to the above, Sahi Savings shall not be held
            liable for any loss or damage or failure to comply or delay in
            complying with its obligations under these Terms and Conditions
            which is caused directly or indirectly by any event or circumstances
            beyond Sahi Savings’ reasonable control. These include system
            failure, network errors, delay or loss of data due to the above and
            in circumstances of acts of God, floods, epidemics, quarantine,
            riot, civil commotion and/or war. User further agree that Sahi
            Savings shall not be held liable for any losses, damages, expenses,
            costs, liabilities and claims of whatsoever nature caused by
            fraudulent or unauthorized use or access of user information, and/or
            signatures. Sahi Savings will be under no duty to verify compliance
            with any restrictions on users investment powers.
          </p>
          <p>
            Sahi Savings will not be liable for any loss, damage, cost, charges
            or expenses directly or indirectly caused by reasons of any defects
            or imperfections or mechanical or other failure with relation to
            computer, cable, telex, telephone or postal system.
          </p>
          <p>
            While Sahi Savings will make every effort to have its computer
            systems available at all times, Sahi Savings makes no guarantees
            with respect to the availability of such systems. Sahi Savings will
            make every effort to resolve availability issues such as network
            problems, virus attacks etc. in an expeditious manner.
            Notwithstanding these, Sahi Savings will as such not be liable for
            any loss, damage, cost, charges or expenses directly or indirectly
            caused by reasons of lack of such availability.
          </p>
          <p>
            Sahi Savings shall not be liable for any loss or damage caused by
            reason of failures or delay of the mutual fund to deliver any units
            purchased even though payments have been made for the same, or
            failure or delay in making payment in respect of any units sold,
            though they may have been delivered and user shall hold Sahi Savings
            harmless and free from any claim in respect thereof. Sahi Savings
            shall also not be liable for any delay, failure or refusal of the
            mutual fund in registering or transferring units to user names or
            for any interest, dividend or other loss caused to user arising
            therefrom.
          </p>
          <p>
            In instances of third party claims, Sahi Savings shall not be liable
            for any failure/delay, wherein such claims/losses are arising due to
            a reason entirely attributable to an error or gross negligence of
            the mutual funds/BSE/AMC.
          </p>
          <p>
            <strong>ONLINE PAYMENT – PAYMENT GATEWAY TERMS</strong>
          </p>
          <p>
            The funds transfer for purchase of units of mutual funds will be
            done by using an Electronic Payment Gateway facility, ONLY internet
            banking facility shall be offered for transaction on the
            Application, through a third party net banking service provider
            registered with the Reserve Bank of India. User cannot use or permit
            the use of the payment gateway or any related services for any
            illegal or improper purposes.
          </p>
          <p>
            Users shall utilize the Electronic Payment Gateway at their own
            risk. These risks would include but not be limited to the following
            risks and Sahi Savings disclaims all liability and responsibility
            for any claims, losses, damages, costs of whatsoever nature arising
            due to such risks:
          </p>
          <p>
            Misuse of Password: If any third party obtains access to user
            password of the Investments Services Account, such third party would
            be able to transact on Sahi Savings’ online technology platform.
            User shall ensure that the terms and conditions applicable to the
            use of the Net Banking password as contained in the Electronic
            Payment Gateway for Net Banking are complied with at all times.
          </p>
          <p>
            Internet Frauds: The Internet per se is susceptible to a number of
            frauds, misuse, hacking and other actions, which could affect
            payment instructions given using Electronic Payment Gateway or the
            transactions done using Sahi Savings’ online technology platform.
            Whilst Sahi Savings shall aim to provide security to prevent the
            same, there cannot be any guarantee against such Internet frauds,
            hacking and other actions. User shall separately evolve/evaluate all
            risks arising out of the same.
          </p>
          <p>
            Mistakes and Errors: The filling in of applicable data for transfer
            would require proper, accurate and complete details. In the event of
            user account receiving an incorrect credit by reason of a mistake
            committed by any third party, the concerned mutual fund/AMC or the
            bank shall be entitled to reverse the incorrect credit at any time
            whatsoever without the consent of the User. User shall be liable and
            responsible to accept any such instructions received from the AMC,
            without questions for any unfair or unjust gain obtained by Sahi
            Savings as a result of the same.
          </p>
          <p>
            Technology Risks: The technology for enabling the transfer of funds
            and the other services offered by the Electronic Payment and Sahi
            Savings could be affected by virus or other malicious, destructive
            or corrupting code, program or macro. Sahi Savings’ Application or
            the bank may require maintenance and during such time it may not be
            possible to process the user request. This could result in delays in
            the processing of transactions/payment instructions or failure in
            the processing of transactions/payment instructions and other such
            failures and inability. User understand that Sahi Savings disclaims
            all and any liability, whether direct or indirect, whether arising
            out of loss of profit or otherwise arising out of any failure or
            inability by bank/mutual funds/AMCs to process any
            transaction/payment instructions for whatsoever reason. Sahi Savings
            shall not be responsible for any of the aforesaid risks.
          </p>
          <p>
            Limits: Sahi Savings may from time to time impose maximum and
            minimum limits on funds that may be transferred by virtue of the
            payment transfer service given. User realize and accept and agree
            that the same is to reduce the risks of the user. User shall be
            bound by such limits imposed and shall strictly comply with them.
          </p>
          <p>
            Indemnity: User shall indemnify Sahi Savings from and against all
            losses and damages that may be caused as a consequence of breach of
            any of the Electronic Payment Gateway for net banking and the terms
            and conditions mentioned herein above.
          </p>
          <p>
            Authentication of the Transaction: User are authorized to make
            transaction only with and from the bank account(s) registered on the
            Application. Banks has all the right to cancel, reject and/or
            unauthorize the transaction made by the user, from any other
            account, details of which is not registered or provided to Sahi
            Savings. Such payment which is rejected by the banks shall be
            notified or intimated to the user, in the morning of the next
            business day.
          </p>
          <p>
            Transaction Verification: The transaction made by the user, whether
            approved or rejected, using the Sahi Savings shall be subject to the
            following level of verifications:
          </p>
          <p>
            Sahi Savings: Transaction will be rejected in case the bank account
            though which the payment is made, is not registered with Sahi
            Savings;
          </p>
          <p>
            BSE: Transaction made from the bank account which is different from
            the account details provided by Sahi Savings to BSE.
          </p>
          <p>
            AMC: Final, rejection could be made by the AMC, upon receiving the
            transaction details.
          </p>
          <p>
            Withdrawal of Facility: Sahi Savings shall be entitled to withdraw
            this service at any time without assigning any reason whatsoever.
          </p>
          <p>
            Binding nature of above terms and conditions: By use of this
            facility, user shall be deemed to have agreed to all the above terms
            and conditions and such terms and conditions shall bound the User in
            the same manner as if the user have agreed to the same in writing.
          </p>
          <p>
            <strong>FUNCTION OF SAHI SAVINGS</strong>
          </p>
          <p>
            Any information contained in Sahi Savings brochures or other
            materials or otherwise communicated by Sahi Savings shall not be
            construed as investment advice and that all decisions to purchase or
            sell units made by user shall be on the basis of personal judgment
            arrived at after due consideration. Sahi Savings does not in any
            manner:
          </p>
          <p>Guarantee payments on any units; or</p>
          <p>Guarantee liquidity of any units; or</p>
          <p>Make any offer to buy back any units; or</p>
          <p>
            Guarantee the redemption or repayment of any units on maturity; or
          </p>
          <p>Guarantee the payments of interest or dividend; or</p>
          <p>Promise, indicate or guarantee any returns; or</p>
          <p>Guarantee any good delivery.</p>
          <p>
            Subscribe to units of mutual funds on behalf or in name of user or
            collects payments from user for the units so purchased by user for
            remitting it further to the AMCs; or
          </p>
          <p>
            Receive any account statement from mutual funds/AMCs, on behalf of
            or in the users name pertaining to the units; or
          </p>
          <p>
            Redeem /sell the units held by user or on its behalf or in its name;
            or unilaterally instruct the mutual fund and/or the corresponding
            AMCs with regards to nomination/changes in investments plan/any
            other changes; or
          </p>
          <p>
            Sign any document on behalf of or in the name of user for purchase,
            sale or redemption of units; or
          </p>
          <p>
            Collect, receive and / or give receipts and discharges for any sum
            including dividend, interest or income arising from the units and
            does not sign and/or endorse dividend and interest warrants on
            my/our behalf or in my/our name; or
          </p>
          <p>
            Correspond with or gives notice to the mutual fund/AMCs on behalf of
            or in the name of user, except for transmission of transactions done
            or purported to be done by user on the online technology platform.
          </p>
          <p>
            Sahi Savings does not make any promises to the user basis the
            graphical representation provided on the Application. The data
            collected from the user to calculate the prospective investments
            amount and is in relation to the past investment history of the user
            and shall not be construed as an authoritative advice to the user.
            Sahi Savings does not offer any advice and nothing herein or on the
            Sahi Savings Application shall be construed as investment advice by
            user.
          </p>
          <p>
            Any sum invested through Sahi Savings Investment Account is not a
            deposit with Sahi Savings and is not bank insured. The same is not
            endorsed or guaranteed and does not constitute obligations of Sahi
            Savings or any of the subsidiaries associates or affiliates
            companies whose role in only as described in these Terms and
            Conditions. Investments in mutual fund are subject to investment
            risk, including the possible loss of principal amount invested. The
            value of the units purchased or not purchased will fluctuate. If
            user redeems the units/shares purchased, user may receive more or
            less than user has/had paid depending upon NAV of the units in the
            fund or trust at the time of redemption. Past results are not a
            guarantee to future performance. Past performance may or may not be
            sustained in the future.
          </p>
          <p>
            <strong>NOTICES/CORRESPONDENCE</strong>
          </p>
          <p>
            Any notice or other correspondence addressed by Sahi Savings to user
            may be addressed to the address provided by the users at the time of
            enrollment. If according to user there is any discrepancy in the
            particular or details of transaction or account of statement then,
            it shall be the responsibility of the user to intimate the same to
            Sahi Savings and to the concerned mutual fund/AMC/authorised
            registrar of the AMC in writing within 7 (seven) working days of
            receiving notice thereof, falling which such transaction, statement
            or account (as the case may be) shall be deemed to be correct and
            accepted by user and users shall not be entitled to question the
            correctness or accuracy thereof.
          </p>
          <p>
            <strong>USER INFORMATION</strong>
          </p>
          <p>
            User shall provide any and all information that may be required by
            Sahi Savings/AMC/authorised service providers of the AMC from time
            to time in compliance with existing legal norms and regulations,
            including but not limited to contact information, address and tax
            related information and any information required under existing or
            future KYC norms and laws. User agrees that Sahi
            Savings/AMC/authorized service providers of the AMC may take steps
            to verify the veracity of such information provided and shall
            co-operate with Sahi Savings/AMC/authorized service providers of AMC
            in providing all information requested by Sahi Savings. User
            acknowledges that failure to provide such information in a timely
            manner may result in ineligibility to avail of some or all of the
            services of Sahi Savings and that Sahi Savings shall in no way be
            held liable for any losses of whatsoever nature resulting from such
            failure to provide information on part of the user. Any information
            provided by the User may be required to be shared with relevant
            regulatory/statutory authorities and User authorises Sahi Savings to
            provide all information to such regulatory / statutory authorities
            as and when required.
          </p>
          <p>
            The information shall be provided by the User and shall be governed
            in accordance with details provided in the privacy policy.
          </p>
          <p>
            <strong>TERMINATION OF SERVICES</strong>
          </p>
          <p>
            Sahi Savings may terminate the Services by giving 90 days prior
            notice in writing to the Registered Customer provided that the
            Registered Customer shall not be relived of obligations hereunder
            notwithstanding such termination incurred prior to the date on which
            such termination shall become effective.
          </p>
          <p>
            <strong>REGULATORY CAUTION</strong>
          </p>
          <p>
            Mutual Fund investments are subject to market risks, read all
            scheme-related documents carefully.
          </p>
          <p>
            <strong>GOVERNING LAW AND DISPUTE RESOLUTION</strong>
          </p>
          <p>
            All disputes and differences arising out of, under or in connection
            with these terms and conditions or anything done hereunder shall be
            within the exclusive jurisdiction only to the courts of Mumbai.
            These terms and conditions are subject to and shall be construed in
            accordance with the laws prevalent in India.
          </p>
          <p>
            In the event of dispute between the User and Sahi Savings on
            accuracy of transaction details provided by the User on the Sahi
            Savings Application, the transaction logs maintained by Sahi Savings
            back office will be the only source of data to verify the accuracy
            of such transactions.
          </p>
          <p>&nbsp;</p>
          <p>
            <strong>BROKERAGE</strong>
          </p>
          <p>
            For all investments made using Sahi Savings, we receive brokerages
            from respective Asset Management Companies. The recommendations are
            not made on the basis of brokerage, and performance / fund details
            of all the mutual funds from competing AMCs are also provided to the
            user for reference.
          </p>
        </div>
      </section>
    </>
  );
};

export default Termscondition;
