const Testimonialdata = [
  {
    id: "1",
    name: "Akshaya Warke",
    subtitle: "Customer Relationship Manager",
    img: "assets/img/avatars/Akshaya.png",
    revew:
      "“I have been using Sahi Savings for a few months now and it has been extremely helpful in keeping track of my tax liabilities & investment. The interface is user friendly and I would highly recommend this app to anyone looking for a simple and effective way to save on taxes.”",
  },
  {
    id: "2",
    name: "Prashant Bharam",
    subtitle: "Product Manager",
    img: "assets/img/avatars/Prashant.png",

    revew:
      "“Sahi Savings has helped me in tracking all my finances in one app and also managing my tax liabilities as per my CTC. This helped me in understanding what is my tax liability and in which investment asset I need to invest. I've recommended Sahi Savings to my friends as well”",
  },
  {
    id: "3",
    name: "Shardul Tambe",
    subtitle: "Full Stack Developer",
    img: "assets/img/avatars/Shardul.png",

    revew:
      "“Sahi Savings app is worth much more than I expected. I STRONGLY recommend Sahi savings app to EVERYONE interested in tracking their investments! I would also like to say thank you to all your staff. It's exactly what I've been looking for.”‎‏‏‎ ‎‏‏‎ ‎‏‏‎ ‎‏‏‎ ‎‏‏‎ ‎‏‏‎ ‎‏‏‎ ‎‏‏‎ ‎‏‏‎ ‎‏‏‎ ‎‏‏‎ ‎‏‏‎ ‎‏‏‎ ‎‏‏‎ ‎‏‏‎ ‎‏‏‎ ‎‏‏‎ ‎‏‏‎ ‎‏‏‎ ‎‏‏‎ ‎‏‏‎ ‎‏‏‎ ‎‏‏‎ ‎‏‏‎ ‎‏‏‎ ‎‏‏‎ ‎‏‏‎ ‎‏‏‎ ‎‏‏‎ ‎‏‏‎ ‎‏‏‎ ‎‏‏‎ ‎‏‏‎ ‎‏‏‎ ‎‏‏‎ ‎‏‏‎ ‎‏‏‎ ‎‏‏‎ ‎‏‏‎ ‎‏‏‎ ‎‏‏‎ ‎‏‏‎ ‎‏‏‎ ‎‏‏‎ ‎‏‏‎ ‎‏‏‎ ‎‏‏‎ ‎‏‏‎ ‎‏‏‎ ‎‏‏‎ ‎‏‏‎ ‎‏‏‎ ‎‏‏‎ ‎‏‏‎ ‎‏‏‎ ‎‏‏‎ ‎‏‏‎ ‎‏‏‎ ‎‏‏‎ ‎‏‏‎ ‎‏‏‎ ‎‏‏‎ ‎‏‏‎ ‎", 
  },
  /*{
    id: "4",
    name: "Coriss Ambady",
    subtitle: "Financial Analyst",
    img: "assets/img/avatars/test4.jpg",

    revew:
      "“Vivamus sagittis lacus vel augue laoreet rutrum faucibus dolor auctor. Vestibulum id ligula porta felis euismod semper. Cras justo odio dapibus facilisis sociis natoque penatibus.”",
  },
  {
    id: "5",
    name: "Coriss Ambady",
    subtitle: "Financial Analyst",
    revew:
      "“Vivamus sagittis lacus vel augue laoreet rutrum faucibus dolor auctor. Vestibulum id ligula porta felis euismod semper. Cras justo odio dapibus facilisis sociis natoque penatibus.”",
  },
  {
    id: "6",
    name: "Coriss Ambady",
    subtitle: "Financial Analyst",
    revew:
      "“Vivamus sagittis lacus vel augue laoreet rutrum faucibus dolor auctor. Vestibulum id ligula porta felis euismod semper. Cras justo odio dapibus facilisis sociis natoque penatibus.”",
  },*/
];

export default Testimonialdata;
