import React from 'react';
import Breadcrumb from '../Components/Breadcrumb';
import Servicesec from '../Components/Servicesec';

const Services = () => {
  return (
    <>
      {/* <Breadcrumb title={"Services"} /> */}
      <Servicesec
        bg={'bg-soft-light wrapper'}
        height={'row gx-lg-8 gx-xl-10 mb-lg-8 mb-xl-10 align-items-center'}
      />
    </>
  );
};

export default Services;
