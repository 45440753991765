const Servicedata = [
  {
    id: "1",
    src: "img/1.png",
    title: "Investment",
    description:
      "Track all your investment assets available whether it is tax saving or not and have a holistic view of your net worth",
  },
  {
    id: "2",
    src: "img/2.png",
    title: "Insurance",
    description:
      "Track all types of Insurance and pay insurance premium which will automatically get tracked under 80C & 80D which can help in checking tax liability.",
  },
  {
    id: "3",
    src: "img/3.png",
    title: "Loans",
    description:
      "Track all types of Loans whether Home, Personal or Education and have a holistic view of complete liabilities including how much tenure is left and how much EMIs have been paid with repayment schedule & pre-payment calculator.",
  },
  {
    id: "4",
    src: "img/4.png",
    title: "Transaction",
    description:
      "Invest in ELSS or any other Mutual Fund scheme, Stocks & investment in NPS. Investment in Deposit & other financial assets will be introduced in near future. Stay Tuned!",
  },
  {
    id: "5",
    src: "img/5.png",
    title: "Bill Payment",
    description:
      "You can now pay utility bills, insurance premium or missed Loan EMIs on Sahi Savings and automatically the premiums / EMIs paid will get tracked in Tax Plan under respective IT sections.",
  },
  {
    id: "6",
    src: "img/6.png",
    title: "Tax Plan",
    description:
      "Done with your tax saving investments. Now check how much Tax you have saved, and which IT section is pending to invest and save tax. Remember! Without specific goal, do not invest just for savings Taxes! ",
  },
];

export default Servicedata;
