import React from "react";

const Section80GG = () => {
  return (
    <div
      style={{
        padding: "10%",
        justifyContent: "center",
        textAlign: "justify",
      }}
    >
      <p>
        <b>What is 80GG Deduction? </b>{" "}
        <p>
          Section 80GG deduction can be claimed only if HRA is not offered by
          your company. Normally, HRA forms part of your salary and you can
          claim exemptions under HRA. If you do not receive HRA from your
          employer and make payments towards rent for any furnished or
          unfurnished accommodation occupied by you for your own residence, you
          can claim deduction under section 80GG towards rent that you pay.
        </p>
      </p>

      <p>
        <b>
          Who can claim deduction and what are the conditions for claiming
          deduction under section 80GG?
        </b>{" "}
        <li>You are self-employed or salaried</li>{" "}
        <li>
          You have not received HRA at any time during the year for which you
          are claiming 80GG HRA component should not form part of your salary to
          claim 80GG.
        </li>{" "}
        <li>
          You or your spouse or your minor child or HUF of which you are a
          member &ndash; do not own any residential accommodation at the place
          where you currently reside, perform duties of the office, or
          employment or carry on business or profession.
        </li>{" "}
        <li>
          In case you own any residential property at any place, for which your
          Income from house property is calculated under applicable sections (as
          a self-occupied property), no deduction under section 80GG is allowed.
        </li>{" "}
        <li>You need to file Form 10BA with details of payment of rent.</li>
      </p>

      <p>
        <b>What is the maximum amount eligible for Deduction?</b>
        <p>
          The lowest of these will be considered as the deduction under this
          section:
        </p>
      </p>

      <p>
        <li>Rs.5,000 per month</li>{" "}
        <li>
          25% of the total Income (excluding long-term capital gains, short-term
          capital gains under section 111A and Income under Section 115A or 115D
          and deductions under 80C to 80U. Also, income is before making a
          deduction under section 80GG).
        </li>{" "}
        <li>Actual rent less 10% of Income</li>
      </p>
    </div>
  );
};

export default Section80GG;
