import React from "react";

const Section80GGC = () => {
  return (
    <div
      style={{
        padding: "10%",
        justifyContent: "center",
        textAlign: "justify",
      }}
    >
      <p>
        <b>What is Section 80GGC?</b> 
        <p>Section 80GGC of the Income Tax Act
        provides tax deductions for contributions made to political parties.
        This can range from 50% to 100% of the total amount contributed.</p>
      <p>
        As per the Income Tax Act, an individual can donate as much as 10% of
        his or her gross earnings to any political organization of his or her
        choice.
      </p>

      <p>
        Individuals who avail deductions under Section 80GGC have the advantage
        of saving on a sizable portion of tax every financial year in tandem
        with other deductions such as House Rent Allowance, Medical Allowance
        and much more.
      </p></p>

      <p>
        <b>Who can claim deduction under Section 80GGC?</b>
      </p>

      <p>
        <li>
          Deductions under this section can be availed only by individuals ie:
          non-corporate assessees or taxpayers.
        </li>{" "}
        <li>The taxpayer or assessee can be any individual or person</li>{" "}
        <li>The taxpayer or assessee cannot be a local authority</li>{" "}
        <li>
          The taxpayer or assessee cannot be an Artificial Judicial Person, who
          receives funding from the government either partially or completely
        </li>{" "}
        <li>Companies cannot avail of deductions under this Section</li>{" "}
        <li>
          Entities to which Donations or Contributions can be made under Section
          80GGC
        </li>{" "}
        <li>
          As per Section 80GGC, an individual can make a donation or
          contribution only to the following entities:
        </li>{" "}
        An electoral trust Any political party that has been registered under
        Section 29A of the Representation of the People Act, 1951
      </p>

      <p>
        <b>What was the objective to introduce under Section 80GGC?</b>{" "}
        <li>
          This section came into existence via the Finance Act 2009, with the
          objective of introducing transparency into electoral funding as well
          as making it corruption free
        </li>{" "}
        <li>
          Deductions under this section fall under Chapter VIA deductions. This
          means that the total amount of deduction allowed cannot be more than
          the total taxable income of the individual
        </li>{" "}
        <li>
          Deductions under this section are not applicable on tax deducted at
          source on an individual's salary. Only employees who draw a salary
          with no other income from other businesses can avail deductions under
          this section while filing their tax returns.
        </li>
      </p>

      <p>
        <b>
          What is the maximum amount of deduction allowed under Section 80GGC?
        </b>{" "}
        <li>
          Individuals who make donations to any political party can claim
          deductions of up to 100 per cent of their contribution to said party.{" "}
        </li>{" "}
        <li>
          By doing so, the total taxable income of the individual is lowered in
          proportion to the contribution he/she has donated to the concerned
          party. In other words, the entire amount of a taxpayer or assessee
          contribution to a legitimate political party will be allowed as a
          deduction from his or her taxable income.
        </li>
      </p>

      <p>
        <b>How to avail deductions under Section 80GGC?</b>{" "}
        <li>
          Taxpayers or assessees looking to claim deductions under Section 80GGC
          can do so while filing their tax returns simply by including the
          amount of their contribution to a political party in the space
          provided for Section 80GGC in the Income Tax Return form. This Section
          appears under Chapter VI-A deductions in the Income Tax Return form.
        </li>{" "}
        <li>
          Donations made to political parties should be made solely through
          legitimate banking portals such as online internet banking, cheques,
          debit cards, credit cards, demand drafts etc. in order to claim
          deductions under this section.
        </li>
      </p>

      <p>
        <b>What are the Exceptions under Section 80GGC?</b> 
        <p>There are certain
        exceptions under which contributions made to political parties will not
        be eligible for deductions. These exceptions are as follows:</p>
      </p>

      <p>
        <li>
          Any donations or contributions made in monetary form or cash are not
          eligible for deduction under Section 80GGC. This exception to the rule
          was brought into effect on the 1st of April 2014 and applies to every
          financial year commencing from the year 2014-2015 and beyond.
        </li>{" "}
        <li>
          Any donations or contributions to any political party that are made in
          kind are also not eligible for deduction under Section 80GGC. This
          includes gifts or Favours of any kind.
        </li>
      </p>
    </div>
  );
};

export default Section80GGC;
