import "./App.css";
import { BrowserRouter, Routes, Route, Outlet } from "react-router-dom";
import Header from "./Layout/Header";
import Home from "./Home/Home";
import About from "./About/About";
import Services from "./Services/Services";
import Contact from "./Contact/Contact";
import Notfound from "./Pages/Notfound";
import Mslpersonalloan from "./Pages/Mslpersonalloan";
import Shulkpay from "./Pages/Shulkpay";
import Privacypolicy from "./Pages/Privacypolicy";
import Termscondition from "./Pages/Termscondition";
import Refundcancellation from "./Pages/Refundcancellation";
import ScrollToTop from "./Components/ScrollToTop";
import Blogs from "./Blogs/Blogs";
import HeaderWithoutFooter from "./Layout/HeaderWithoutFooter";
import Nave from "./Layout/Nave";
import Section80C from "./Extra/Section80C";
import Section80D from "./Extra/Section80D";
import Section80CCD1B from "./Extra/Section80CCD1B";
import Section80DD from "./Extra/Section80DD";
import Section80DDB from "./Extra/Section80DDB";
import Section80E from "./Extra/Section80E";
import Section80EE from "./Extra/Section80EE";
import Section80EEA from "./Extra/Section80EEA";
import Section80G from "./Extra/Section80G";
import Section80GG from "./Extra/Section80GG";
import Section80GGC from "./Extra/Section80GGC";
import Section80TTA from "./Extra/Section80TTA";
import Section80U from "./Extra/Section80U";
import Section80GGA from "./Extra/Section80GGA";
import Section24 from "./Extra/Section24";
import Section80EEB from "./Extra/Section80EEB";
import Sectionbooksperiodicals from "./Extra/Sectionbooksperiodicals";
import Sectionchildrenallowance from "./Extra/Sectionchildrenallowance";
import Sectionfoodcoupons from "./Extra/Sectionfoodcoupons";
import Sectionhra from "./Extra/Sectionhra";
import Sectionlta from "./Extra/Sectionlta";
import Sectionmobilereimburse from "./Extra/Sectionmobilereimburse";
import Exemptions from "./Extra/Exemptions";
import Deductions from "./Extra/Deductions";
import Npsinvestment from "./Pages/Npsinvestment";
import Npssip from "./Pages/Npssip";
import AccountDeletion from "./Pages/AccountDeletion";


function App() {
  return (
    <>
      <BrowserRouter>
        <ScrollToTop>
          <Routes>
            <Route path="/" element={<Header />}>
              <Route exact index element={<Home />} />
              <Route exact path="about" element={<About />} />
              <Route exact path="mslpersonalloan" element={<Mslpersonalloan/>}/>
              <Route path="/Npsinvestment" element={<Npsinvestment />} />
              <Route path="/Npssip" element={<Npssip />} />
              <Route exact path="shulkpay" element={<Shulkpay />}/>
              <Route exact path="services" element={<Services />} />
              <Route exact path="contact" element={<Contact />} />
              <Route exact path="privacypolicy" element={<Privacypolicy />} />
              <Route exact path="termscondition" element={<Termscondition />} />
              <Route path="/AccountDeletion" element={<AccountDeletion />} />
              <Route
                exact
                path="refundcan_cellation"
                element={<Refundcancellation />}
              />
              <Route path="*" element={<Notfound />} />
            </Route>
            <Route path="/" element={<HeaderWithoutFooter />}>
              <Route exact path="blogs" element={<Blogs />} />
            </Route>
            <Route path="/Section24" element={<Section24 />} />
            <Route path="/Section80C" element={<Section80C />} />
            <Route path="/Section80CCD1B" element={<Section80CCD1B />} />
            <Route path="/Section80D" element={<Section80D />} />
            <Route path="/Section80DD" element={<Section80DD />} />
            <Route path="/Section80DDB" element={<Section80DDB />} />
            <Route path="/Section80E" element={<Section80E />} />
            <Route path="/Section80EE" element={<Section80EE />} />
            <Route path="/Section80EEA" element={<Section80EEA />} />
            <Route path="/Section80G" element={<Section80G />} />
            <Route path="/Section80GG" element={<Section80GG />} />
            <Route path="/Section80GGC" element={<Section80GGC />} />
            <Route path="/Section80TTA" element={<Section80TTA />} />
            <Route path="/Section80U" element={<Section80U />} />
            <Route path="/Section80GGA" element={<Section80GGA />} />
            <Route path="/Section80EEB" element={<Section80EEB />} />
            <Route path="/Sectionbooksperiodicals" element={<Sectionbooksperiodicals />} />
            <Route path="/Sectionchildrenallowance" element={<Sectionchildrenallowance />} />
            <Route path="/Sectionfoodcoupons" element={<Sectionfoodcoupons />} />
            <Route path="/Sectionhra" element={<Sectionhra />} />
            <Route path="/Sectionlta" element={<Sectionlta />} />
            <Route path="/Sectionmobilereimburse" element={<Sectionmobilereimburse />} />
            <Route path="/Exemptions" element={<Exemptions />} />
            <Route path="/Deductions" element={<Deductions />} />
           
            
            
          </Routes>
        </ScrollToTop>
      </BrowserRouter>
    </>
  );
}

export default App;
