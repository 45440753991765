import React from "react";

const Section80EE = () => {
  return (
    <div
      style={{
        padding: "10%",
        justifyContent: "center",
        textAlign: "justify",
      }}
    >
      <p>
        <b>What is Section 80EE?</b>
        <p>
          Tax deduction under Section 80EE of the Income Tax Act 1961, can be
          claimed by first-time home buyers for the amount they pay as interest
          on home loan. The maximum deduction that can be claimed under this
          section is Rs. 50,000 during a financial year. The amount can be
          claimed over and above the deduction of Section 24 (Rs. 2,00,000) and
          Section 80C (Rs. 1,50,000).
        </p>
      </p>

      <p>
        Section 80EE was created for the first time in the FY 2013-14 for
        individual taxpayers to avail tax deduction on interest on home loans.
        At that time, the maximum deduction that could be claimed was Rs.
        1,00,000. This tax benefit was available for only two years - FY 2013-14
        and FY 2014-15. The Section was reintroduced in FY 2016-17, and the
        quantum of deduction was changed to Rs. 50,000 for interest paid towards
        home loan.
      </p>

      <p>
        <b>Conditions for Claiming Section 80EE Deductions</b>
        <p>
          The conditions associated with claiming deductions under Section 80EE
          are:
        </p>
      </p>

      <p>
        <li>This must be the first house that the taxpayer has purchased.</li>{" "}
        <li>The value of the house should be Rs. 50 lakhs or less.</li>{" "}
        <li>The home loan availed should be Rs. 35 lakhs or less.</li>{" "}
        <li>
          Section 80EE allows deduction only for the interest portion of a home
          loan.
        </li>{" "}
        <li>
          The home loan has been sanctioned by a Housing Finance Company or a
          Financial Institution.
        </li>{" "}
        <li>
          As on the date of the loan sanction, the individual must not own
          another house.
        </li>{" "}
        <li>
          The loan should not have been availed for commercial properties.
        </li>{" "}
        <li>
          For claiming deductions under this section, the loan should have been
          sanctioned between 01.04.16 to 31.03.17.
        </li>
      </p>

      <p>
        <b>Eligibility for Claiming Section 80EE Deductions</b>
      </p>

      <p>
        <li>
          Only individual taxpayers can claim deduction under Section 80EE on
          properties purchased either singly or jointly. If an individual has
          bought a property jointly with his or her spouse and they are both
          paying the instalments of the loan, then the two can individually
          claim this deduction.
        </li>{" "}
        <li>
          The tax benefits are not applicable for Hindu Unified Families (HUF),
          Association of Persons (AOP), companies, trusts, etc.
        </li>{" "}
        <li>
          Tax benefits under Section 80EE can only be claimed by first-time home
          buyers. In order to claim this deduction, the individual must have
          taken the loan from a financial institution for buying his/her first
          residential house property.
        </li>{" "}
        <li>
          Section 80EE is applicable on a per person basis rather than a per
          property basis.
        </li>{" "}
        <li>
          To claim this benefit, it is not necessary for the taxpayer to reside
          in the property for which he or she is claiming this deduction.
          Borrowers living in rented homes can also claim this deduction.
        </li>
      </p>

      <p>
        <b>How to claim 80EE Tax Deductions?</b>
        <p>
          A taxpayer can claim deduction under Section 80EE at the time of
          filing tax returns. How much can one claim as deduction? See below:
        </p>
      </p>

      <p>
        <ul>
          1. Calculate the total amount of interest that is paid during a
          financial year on the home loan.
        </ul>
        <ul>
          2. Once the total interest amount paid is ascertained, claim deduction
          up to Rs. 2,00,000 (under Section 24 of Income Tax Act, 1961).
        </ul>
        <ul>
          3. The balance amount, up to Rs. 50,000, can be claimed under Section
          80EE of Income Tax Act, 1961.
        </ul>
      </p>

      <p>
        <b>Features of the Deduction</b> 
        <p>The features of Section 80EE are as
        under:</p>
      </p>

      <p>
        <li>
          The deduction under Section 80EE can only be claimed by individual
          taxpayers on properties purchased either singly or jointly. It is not
          applicable for Hindu Unified Families (HUF), Association of Persons
          (AOP), companies, trusts, etc.
        </li>{" "}
        <li>
          The maximum deduction that can be claimed under this section is Rs.
          50,000 during a financial year.
        </li>{" "}
        <li>
          The deduction that can be claimed is above and beyond the limit of Rs.
          2,00,000, as under Section 24 of the Income Tax Act.
        </li>{" "}
        <li>The property can be either self-occupied or non-self-occupied.</li>
      </p>

      <p>
        <b>Comparison between Section 80EE and Section 24</b> 
        <p>Deduction can be
        claimed for interest on home loan under Section 24 of the Income Tax
        Act, 1961 (Limit is Rs. 2,00,000). This deduction can only be claimed if
        the owner or his or her family members reside in the house property. The
        entire interest shall be waived off as a deduction in case the house is
        on rent.</p>
      </p>

      <p>
        If one is able to meet the conditions of both the sections i.e. Section
        24 and Section 80EE, the individual can avail benefits under the two. To
        do so, the individual will first need to exhaust the limit under Section
        24 and then claim the additional benefit under section 80EE. Therefore,
        the deduction under Section 80EE is in addition to the limit of Rs.
        2,00,000, as under Section 24.
      </p>
    </div>
  );
};

export default Section80EE;
