
import React, { useRef, useState,} from "react";
import { TailSpin } from "react-loading-icons";
import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import {useNavigate} from "react-router-dom"
import axios from 'axios';


const Mslpersonalloan = () => {
  const navigate = useNavigate();
  const [name, setName]=useState('');
  const [phone, setPhone]=useState('');
  const notify = () => toast.success("Message send successfully");
  const notifyerr = () => toast.error("Please enter valid details");
  const form = useRef();

  // retrived data state
  const [data, setData]=useState(false);
  
  // submit event
  const handleSubmit=(e)=>{
    setData(true);
    e.preventDefault();
    console.log(name, phone);

    // our object to pass
    const data = {
      name,phone
    }

    axios.post('https://sheet2api.com/v1/s2hMcruNTl26/myshubhlife',data)
    .then(
      (response) => {
        setData(false);
       
         notify();
         console.log(response);
         setName('');
         setPhone('');
         window.location.replace('https://www.myshubhlife.com/apply/#/?utm_source=SCSAHI');
      },
      (error) => {
        setData(false);
        notifyerr();
      })
  }

  return (
    <>
    {/* <Breadcrumb title={"Contact Us"} /> */}
    <section className="wrapper bg-light">
      <div className="container pb-md-14 py-md-15 mt-15 mt-md-10">
        <div className="row">
          <div className="col-xl-10 mx-auto">
            <div className="row justify-content-center">
              <div className="col-lg-5">
                <div className="p-2" style={{  background: "#175783", boxShadow: "0px 3px 10px"}}>
                <div>
                <img
                src="img/bannermyshubhlife.png"
                className="img-fluid mt-2"
                alt=""
              />
                </div>
                <form
                  className="contact-form needs-validation col-mt-116 mt-5"
                  method=""
                  ref={form}
                  // onSubmit={sendEmail}
                  onSubmit={handleSubmit}
                >
                  <div className="messages"></div>
                  <div className="row gx-4">
                    <div className="col-md-12">
                      <div className="form-floating mb-4">
                        <input
                          id="form_name"
                          type="text"
                          name="name"
                          className="form-control"
                          placeholder="Name"
                          onChange={(e)=>setName(e.target.value)}
                          value={name}
                          required
                        />
                        <label htmlFor="form_name">Enter your name *</label>
                        <div className="valid-feedback"> Looks good! </div>
                        <div className="invalid-feedback">
                          {" "}
                          Please enter your name.{" "}
                        </div>
                      </div>
                    </div>

                    <div className="col-md-12">
                      <div className="form-floating mb-4">
                        <input
                          id="form_email"
                          type="tel"
                          name="phone"
                          className="form-control"
                          placeholder="Enter your mobile number"
                          onChange={(e)=>setPhone(e.target.value)}
                          value={phone}
                          required
                        />
                        <label htmlFor="form_email">Enter your mobile number *</label>
                        <div className="valid-feedback"> Looks good! </div>
                        <div className="invalid-feedback">
                          {" "}
                          Please provide a valid mobile number.{" "}
                        </div>
                      </div>
                    </div>

                    
                    <div className="col-12">
                      <button
                        type="submit"
                        className="btn btn-primary rounded-pill btn-send mb-3 d-flex mx-auto"
                        style={{ width: "200px" }}
                        disabled={data ? "" : ""}
                      >
                        {data ? <TailSpin /> : "SUBMIT"}
                      </button>
                    </div>
                  </div>
                  <ToastContainer />
                </form>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </section>
  </>
  )
}

export default Mslpersonalloan;