import Breadcrumb from "../Components/Breadcrumb";
import { FiPhone, FiMail, FiMapPin } from "react-icons/fi";
import React, { useRef, useState } from "react";
import emailjs, { send } from "@emailjs/browser";
import { TailSpin } from "react-loading-icons";
import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import axios from "axios";

const Contact = () => {
  const [formData, setFormData] = useState({
    name: "",
    email: "",
    phone: "",
    subject: "",
    message: "",
    // Add other form fields here
  });
  const [responseMessage, setResponseMessage] = useState("");
  const [error, setError] = useState("");
  // Handle form input changes
  const handleChange = (e) => {
    setFormData({ ...formData, [e.target.name]: e.target.value });
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    // Create FormData object
    const dataToSubmit = new FormData();
    for (const key in formData) {
      dataToSubmit.append(key, formData[key]);
    }

    // Axios POST request
    try {
      const response = await axios({
        method: "post",
        url: "https://admin.sahisavings.com/api/contactus",
        data: dataToSubmit,
        headers: { "Content-Type": "application/json" },
      });
      setResponseMessage(response.data.savecontact);
      setError(response.data.errors);
      setFormData({
        name: "",
        email: "",
        phone: "",
        subject: "",
        message: "",
      });
    } catch (error) {
      console.error(error.message);
    }
  };
  return (
    <>
      {/* <Breadcrumb title={"Contact Us"} /> */}
      <section className="wrapper bg-light">
        <div className="container pb-14 py-md-15">
          <div className="row">
            <div className="col-xl-10 mx-auto">
              <div className="row gy-10 gx-lg-8 gx-xl-12">
                <div className="col-lg-7">
                  <form
                    className="contact-form needs-validation col-mt-116"
                    method=""
                    onSubmit={handleSubmit}
                  >
                    <div className="messages"></div>
                    <div className="row gx-4">
                      <div className="col-md-6">
                        <div className="form-floating mb-4">
                          {error?.name ? (
                            <div
                              className="alert alert-sm alert-danger d-flex align-items-center py-1"
                              role="alert"
                            >
                              <div>{error.name}</div>
                            </div>
                          ) : null}
                          <input
                            id="form_name"
                            type="text"
                            value={formData.name}
                            onChange={handleChange}
                            name="name"
                            className="form-control"
                            placeholder="Name"
                            required
                          />
                          <label htmlFor="form_name">Name *</label>
                          <div className="valid-feedback"> Looks good! </div>
                          <div className="invalid-feedback">
                            {" "}
                            Please enter your name.{" "}
                          </div>
                        </div>
                      </div>

                      <div className="col-md-6">
                        <div className="form-floating mb-4">
                          {error?.email ? (
                            <div
                              className="alert alert-sm alert-danger d-flex align-items-center py-1"
                              role="alert"
                            >
                              <div>{error.email}</div>
                            </div>
                          ) : null}
                          <input
                            id="form_email"
                            type="email"
                            value={formData.email}
                            onChange={handleChange}
                            name="email"
                            className="form-control"
                            placeholder="gmail.doe@example.com"
                            required
                          />
                          <label htmlFor="form_email">Email *</label>
                          <div className="valid-feedback"> Looks good! </div>
                          <div className="invalid-feedback">
                            {" "}
                            Please provide a valid email address.{" "}
                          </div>
                        </div>
                      </div>
                      <div className="col-md-6">
                        <div className="form-floating mb-4">
                          {error?.phone ? (
                            <div
                              className="alert alert-sm alert-danger d-flex align-items-center py-1"
                              role="alert"
                            >
                              <div>{error.phone}</div>
                            </div>
                          ) : null}
                          <input
                            id="form_name"
                            type="text"
                            className="form-control"
                            pattern="^-?[0-9]\d*\.?\d*$"
                            placeholder="Your phone"
                            value={formData.phone}
                            onChange={handleChange}
                            name="phone"
                            required
                          />
                          <label htmlFor="form_name">Phone Number *</label>
                          <div className="valid-feedback"> Looks good! </div>
                          <div className="invalid-feedback">
                            {" "}
                            Please enter your name.{" "}
                          </div>
                        </div>
                      </div>

                      <div className="col-md-6">
                        <div className="form-floating mb-4">
                          {error?.subject ? (
                            <div
                              className="alert alert-sm alert-danger d-flex align-items-center py-1"
                              role="alert"
                            >
                              <div>{error.subject}</div>
                            </div>
                          ) : null}
                          <input
                            id="form_email"
                            type="text"
                            placeholder="Subject"
                            value={formData.subject}
                            onChange={handleChange}
                            name="subject"
                            className="form-control"
                            required
                          />
                          <label htmlFor="form_email">Subject *</label>
                          <div className="valid-feedback"> Looks good! </div>
                          <div className="invalid-feedback">
                            {" "}
                            Please provide a valid email address.{" "}
                          </div>
                        </div>
                      </div>

                      <div className="col-12">
                        <div className="form-floating mb-4">
                          {error?.message ? (
                            <div
                              className="alert alert-sm alert-danger d-flex align-items-center py-1"
                              role="alert"
                            >
                              <div>{error.message}</div>
                            </div>
                          ) : null}
                          <textarea
                            id="form_message"
                            name="message"
                            value={formData.message}
                            onChange={handleChange}
                            className="form-control"
                            placeholder="Your message"
                            style={{ height: "150px" }}
                            required
                          ></textarea>
                          <label htmlFor="form_message">Message *</label>
                          <div className="valid-feedback"> Looks good! </div>
                          <div className="invalid-feedback">
                            {" "}
                            Please enter your messsage.{" "}
                          </div>
                        </div>
                      </div>

                      <div className="col-12">
                        {responseMessage ? (
                          <div className="alert alert-success" role="alert">
                            {responseMessage}
                          </div>
                        ) : null}
                        <button
                          type="submit"
                          className="btn btn-primary rounded-pill btn-send mb-3"
                          style={{ width: "200px" }}
                        >Send message
                        </button>
                      </div>
                    </div>
                  </form>
                </div>

                <div className="col-lg-5">
                  <div className="d-flex flex-row">
                    <div>
                      <div className="icon text-primary fs-28 me-4 mt-n1">
                        {" "}
                        <FiMapPin />{" "}
                      </div>
                    </div>
                    <div>
                      <h5 className="mb-1">Address</h5>
                      <address>
                        Flat no 15 B Wing Andheri Indradarshan Near St Louis
                        School Four Bungalows Andheri West Mumbai 400053
                      </address>
                    </div>
                  </div>
                  <div className="d-flex flex-row">
                    <div>
                      <div className="icon text-primary fs-28 me-4 mt-n1">
                        {" "}
                        <FiPhone />{" "}
                      </div>
                    </div>
                    <div>
                      <h5 className="mb-1">Phone</h5>
                      <a href="tel:+91 8976120023">+91 8976120023</a>
                    </div>
                  </div>
                  <div className="d-flex flex-row">
                    <div>
                      <div className="icon text-primary fs-28 me-4 mt-n1">
                        {" "}
                        <FiMail />{" "}
                      </div>
                    </div>
                    <div>
                      <h5 className="mb-1">E-mail</h5>
                      <p className="mb-0">
                        <a
                          href="mailto:support@sahisavings.com"
                          className="link-body"
                        >
                          support@sahisavings.com
                        </a>
                      </p>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>
    </>
  );
};

export default Contact;
