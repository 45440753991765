import React from "react";

const Section80DDB = () => {
  return (
    <div
      style={{
        padding: "10%",
        justifyContent: "center",
        textAlign: "justify",
      }}
    >
      <p>
        {" "}
        <b>What is Section 80DDB?</b>
      </p>

      <p>
        Section 80DDB is allowed for medical treatment of a dependant who is
        suffering from a specified disease which has been listed below:
      </p>
      <p>
        <li>
          Neurological Diseases where the disability level has been certified to
          be of 40% and above
        </li>{" "}
        <ul>a) Dementia </ul>
        <ul>b) Dystonia Musculorum Deformans</ul>
        <ul>c) Motor Neuron Disease</ul>
        <ul>d) Ataxia </ul>
        <ul>e) Chorea </ul>
        <ul>f) Hemiballismus </ul>
        <ul>g) Aphasia</ul>
        <ul>h) Parkinsons Disease</ul>
        <li>Malignant Cancers</li>{" "}
        <li>Full Blown Acquired Immuno-Deficiency Syndrome (AIDS)</li>{" "}
        <li>Hematological disorders</li>
        <ul>a) Hemophilia </ul>
        <ul>b) Thalassaemia</ul>
        <li>Chronic Renal failure</li>
      </p>

      <p>
        <b>Who can claim deductions under Section 80DDB?</b>
        <li>Can be claimed by an Individual or HUF</li>{" "}
        <li>Allowed to Resident Indians</li>{" "}
        <li>
          When taxpayer has spent money on treatment of the dependant (Dependant
          shall mean spouse, children, parents and siblings)
        </li>{" "}
        <li>
          In case the dependant is insured and some payment is also received
          from an insurer or reimbursed from an employer, such insurance or
          reimbursement received shall be subtracted from the deduction.
        </li>
      </p>

      <p>
        <b>Amount allowed as a deduction</b>
        <li>
          <b>From FY 2018-19 onwards (Assessment Year 2019-20 Onwards)</b>
        </li>
        40,000/- or the amount actually paid, whichever is less. In the case of
        a senior citizen and super-senior citizen, Rs.1,00,000 or amount
        actually paid, whichever is less.
      </p>
      <p>
        <li>
          <b>
            From FY 2015-16 to FY 2017-18 (Assessment Year 2016-17 to Assessment
            Year 2018-19)
          </b>
        </li>{" "}
        40,000/- or the amount actually paid, whichever is less. In the case of
        a senior citizen, Rs, 60,000 or amount actually paid, whichever is less.
        For very senior citizens Rs 80,000 is the maximum deduction that can be
        claimed.
      </p>

      <p>
        <li>
          <b>For FY 2014-15 (Assessment Year 2015-16)</b>
        </li>
        Rs 40,000/- or the amount actually paid,
        whichever is less. In the case of a senior citizen, Rs 60,000 or amount
        actually paid, whichever is less.
      </p>
    </div>
  );
};

export default Section80DDB;
