import React, { useRef, useState } from "react";
import axios from "axios";
import { FiPhone, FiMail, FiMapPin } from "react-icons/fi";

const AccountDeletion = () => {
    const [formData, setFormData] = useState({
        name: "",
        email: "",
        mobile_number: "",
        sub: "",
        // Add other form fields here
      });
      const [responseMessage, setResponseMessage] = useState("");
      const [error, setError] = useState("");
      // Handle form input changes
      const handleChange = (e) => {
        setFormData({ ...formData, [e.target.name]: e.target.value });
      };
    
      const handleSubmit = async (e) => {
        e.preventDefault();
        // Create FormData object
        const dataToSubmit = new FormData();
        for (const key in formData) {
          dataToSubmit.append(key, formData[key]);
        }
    
        // Axios POST request
        try {
          const response = await axios({
            method: "post",
            url: "https://admin.sahisavings.com/api/account_deletion",
            data: dataToSubmit,
            headers: { "Content-Type": "application/json" },
          });
          setResponseMessage(response.data.account_deletion);
          console.log(response.data)
          setError(response.data.errors);
          setFormData({
            name: "",
            email: "",
            mobile_number: "",
            sub: "",
          });
        } catch (error) {
          console.error(error.message);
        }
      };

  return (
    <>
    <section className="wrapper bg-light">
        <div className="container pb-14 py-md-15">
          <div className="row justify-content-center">
            <div className="col-xl-4">
            <div className="card p-3">
            <div className="col-lg-12">
                <h5 className="text-center mb-3">User Account Deletion</h5>
                  <form
                    className="contact-form needs-validation col-mt-116"
                    method=""
                    onSubmit={handleSubmit}
                  >
                    <div className="messages"></div>
                    <div className="row gx-4">
                      <div className="col-md-12">
                        <div className="form-floating mb-4">
                          {error?.name ? (
                            <div
                              className="alert alert-sm alert-danger d-flex align-items-center py-1"
                              role="alert"
                            >
                              <div>{error.name}</div>
                            </div>
                          ) : null}
                          <input
                            id="form_name"
                            type="text"
                            value={formData.name}
                            onChange={handleChange}
                            name="name"
                            className="form-control"
                            placeholder="Name"
                            required
                          />
                          <label htmlFor="form_name">Name *</label>
                          <div className="valid-feedback"> Looks good! </div>
                          <div className="invalid-feedback">
                            {" "}
                            Please enter your name.{" "}
                          </div>
                        </div>
                      </div>

                      <div className="col-md-12">
                        <div className="form-floating mb-4">
                          {error?.email ? (
                            <div
                              className="alert alert-sm alert-danger d-flex align-items-center py-1"
                              role="alert"
                            >
                              <div>{error.email}</div>
                            </div>
                          ) : null}
                          <input
                            id="form_email"
                            type="email"
                            value={formData.email}
                            onChange={handleChange}
                            name="email"
                            className="form-control"
                            placeholder="gmail.doe@example.com"
                            required
                          />
                          <label htmlFor="form_email">Email *</label>
                          <div className="valid-feedback"> Looks good! </div>
                          <div className="invalid-feedback">
                            {" "}
                            Please provide a valid email address.{" "}
                          </div>
                        </div>
                      </div>
                      <div className="col-md-12">
                        <div className="form-floating mb-4">
                          {error?.mobile_number ? (
                            <div
                              className="alert alert-sm alert-danger d-flex align-items-center py-1"
                              role="alert"
                            >
                              <div>{error.mobile_number}</div>
                            </div>
                          ) : null}
                          <input
                            id="form_name"
                            type="text"
                            className="form-control"
                            pattern="^-?[0-9]\d*\.?\d*$"
                            placeholder="Your phone"
                            value={formData.mobile_number}
                            onChange={handleChange}
                            name="mobile_number"
                            required
                          />
                          <label htmlFor="form_name">Phone Number *</label>
                          <div className="valid-feedback"> Looks good! </div>
                          <div className="invalid-feedback">
                            {" "}
                            Please enter your name.{" "}
                          </div>
                        </div>
                      </div>

                      <div className="col-md-12">
                        <div className="form-floating mb-4">
                          {error?.subs ? (
                            <div
                              className="alert alert-sm alert-danger d-flex align-items-center py-1"
                              role="alert"
                            >   
                              <div>{error.subs}</div>
                            </div>
                          ) : null}
                          <input
                            id="form_email"
                            type="text"
                            placeholder="Subject"
                            value={formData.sub}
                            onChange={handleChange}
                            name="sub"
                            className="form-control"
                            required
                          />
                          <label htmlFor="form_email">Subject *</label>
                          <div className="valid-feedback"> Looks good! </div>
                          <div className="invalid-feedback">
                            {" "}
                            Please provide a valid email address.{" "}
                          </div>
                        </div>
                      </div>

                      <div className="col-12 text-center">
                        {responseMessage ? (
                          <div className="alert alert-success" role="alert">
                            {responseMessage}
                          </div>
                        ) : null}
                        <button
                          type="submit"
                          className="btn btn-primary rounded-pill btn-send mb-3 "
                          style={{ width: "200px" }}
                        >Submit
                        </button>
                      </div>
                    </div>
                  </form>
                </div>
            </div>
            </div>
          </div>
        </div>
      </section></>
  )
}

export default AccountDeletion