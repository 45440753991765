import React from "react";
import { RiGooglePlayLine } from "react-icons/ri";
import Fade from "react-reveal/Fade";
import Flash from "react-reveal/Flash";
import { Link } from "react-router-dom";

const Carousel = () => {
  return (
    <>
      <section
        className="wrapper bg-soft-primary"
        style={{ position: "relative", top: "50px" }}
      >
        <div className="container pt-5 pb-15 py-lg-17 py-xl-19 pb-xl-20 position-relative">
          <img
            className="top_img position-lg-absolute col-12 col-lg-10 col-xl-11 col-xxl-10 px-lg-5 px-xl-0 ms-n5 ms-sm-n8 ms-md-n10 ms-lg-0 mb-md-4 mb-lg-0 w-65"
            src="img/devices.webp"
            alt=""
            data-show="true"
          />
          <div className="row gx-0 align-items-center">
            <div className="col-md-10 offset-md-1 col-lg-5 offset-lg-7 offset-xxl-6 ps-xxl-12 mt-md-n9 text-center text-lg-start">
              <Fade right>
                <h1 className="display-2 mb-4 mx-sm-n2 mx-md-0">
                  Curious to check whether you have invested enough to save tax
                  for current FY?
                </h1>
                <p className="lead fs-lg mb-7 px-md-10 px-lg-0">
                  Track and manage all your tax saving & other investments in
                  one place.
                </p>
                <div className="d-flex justify-content-center justify-content-lg-start">
                  <span>
                    <a
                      target={"_blank"}
                      href="https://play.google.com/store/apps/details?id=com.sahisavings"
                      className="btn btn-green btn-icon btn-icon-start rounded"
                    >
                      <RiGooglePlayLine
                        style={{ fontSize: "20px", marginRight: "10px" }}
                      />{" "}
                      Google Play
                    </a>
                  </span>
                </div>
              </Fade>
            </div>
          </div>
        </div>
      </section>
    </>
  );
};

export default Carousel;
