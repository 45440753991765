import React from "react";

const Section80D = () => {
  return (
    <div
      style={{
        padding: "10%",
        justifyContent: "center",
        textAlign: "justify",
      }}
    >
      <p>
        <b>What is Section 80D?</b>
        <p>
          Every individual or HUF can claim a deduction from their total income
          for medical insurance premiums paid in a given year under Section 80D.
          This deduction is also available for top-up health and critical
          illness plans.
        </p>
      </p>

      <p>
        The deduction benefit is available not only for self but also for buying
        the policy to cover your spouse, or your dependent children or parents.
        Section 80D deduction is over and above the deductions claimed under
        Section 80C.
      </p>

      <p>
        <b>Who is eligible for deduction under Section 80D?</b>
        <p>
          Deduction for medical insurance premiums and medical expenses for
          senior citizens is allowed to the Individual or HUF category of
          taxpayers only. Only Individual or HUF taxpayers are eligible for
          deduction, other entities cannot claim this deduction.
          <p>Health insurance can be availed for: </p>
          <p>
            <li>Self</li>
            <li>Spouse</li>
            <li>Dependant children</li>
            <li>Parents</li>
          </p>
          <p>
            <b>An individual or HUF can claim deduction under Section 80D for the
            premium payments & expenses mentioned below:</b>
          </p>
          <p>
            <li>
              A medical insurance premium paid for self, spouse, children or
              dependent parents in any mode other than cash.
            </li>
            <li>
              Expenditure incurred on the account of preventive health check-up
            </li>
            <li>
              Medical expenditure incurred on the health of senior citizens
              (aged 60 years or above) who is not covered under any health
              insurance scheme.
            </li>
            <li>
              The contribution made to the Central Government health scheme or
              any scheme as notified by the government.
            </li>
          </p>
        </p>
      </p>
      <p>
        {" "}
        <b>For Individual: </b>
      </p>

      <p>
        <li>
          An individual can claim a deduction of up to Rs 25,000 for the
          insurance of self, spouse, and dependent children.
        </li>
        <li>
          An additional/separate deduction for parents insurance is available to
          the extent of Rs 25,000 if they are less than 60 years of age, or Rs
          50,000 if your parents are aged above 60.
        </li>
        <li>
          If medical expenses incurred for senior citizens (self, spouse,
          dependent children, parents) not covered under any medical insurance,
          you can claim deduction for the said expenses incurred under the above
          limit of Rs 50,000.
        </li>
        <li>
          If both the taxpayer and parents are aged more than 60 years, for whom
          the medical covers have been taken, the maximum deduction that can be
          availed under this section is Rs 1,00,000. If the medical expenditure
          done on senior citizens (taxpayer/family and parents) is not covered
          under any health insurance, you can claim deduction for the said
          expenses within the said limit. Senior citizens include senior and
          very senior citizens above 60 years of age.
        </li>
      </p>

      <p>
        <b>For HUF</b>
      </p>
      <p>
        <li>
          HUF can claim a deduction under Section 80D for a mediclaim taken for
          any of the members of the HUF.
        </li>
        <li>
          This deduction will be Rs 25,000 if the member insured is less than 60
          years, and will be Rs 50,000 if the insured is 60 years of age or
          more.
        </li>
      </p>
    </div>
  );
};

export default Section80D;
