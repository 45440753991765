import React, { useEffect, useState } from "react";
import axios, * as others from "axios";
import "./Blogs.scss";
import Card from "react-bootstrap/Card";
import parse from "html-react-parser";
import Badge from "react-bootstrap/Badge";
import { Link } from "react-router-dom";
const Blogs = () => {
  const [loading, setloading] = useState(true);

  const [blogdata, setblogdata] = useState(null);
  const mediumURL =
    // "https://api.rss2json.com/v1/api.json?rss_url=https://medium.com/feed/@mehulkothari05";
  "https://api.rss2json.com/v1/api.json?rss_url=https://medium.com/feed/@sahisavings";

  const fetchdata = async () => {
    try {
      setloading(true);
      await axios({
        url: mediumURL,
        method: "GET",
        responseType: "json",
      }).then((data) => {
        // console.log(data.data)
        const avatar = data.data.feed.image;
        const profileLink = data.data.feed.link;
        const res = data.data.items; //This is an array with the content. No feed, no info about author etc..
        const posts = res.filter((item) => item.categories.length > 0);
        const title = data.data.feed.title;
        setblogdata(data.data);
        console.log(data, res);
        console.log("here", data);
        setloading(false);
      });
    } catch (err) {
      console.log("error fetching ");
      console.log(err);
    }
  };
  useEffect(() => {
    fetchdata();
  }, []);
  return (
    <div>
      <div className="Blogs">
        <div className="blogscontainer">
          {/* <Card style={{ width: "auto" }}>
            <Card.Img
              variant="top"
              src="http://t0.gstatic.com/licensed-image?q=tbn:ANd9GcQH9nAHfjkj6sn0l5RXB8VLCXdu60qBfTa4O1rKmPIHW_5guMQHppZ3K329chY91fbmMzEpZDZlWa7gD34"
            />
            <Card.Body>
              <Card.Title>Card Title</Card.Title>
             
            </Card.Body>
          </Card> */}

          {blogdata ? (
            blogdata.items.map((data) => (
              <a href={data.link} target="_blank">
                <Card
                  style={{
                    width: "auto",
                    display: "flex",
                    flexDirection: "column",
                  }}
                >
                  {/* {console.log(data.content)} */}
                  {console.log(typeof data.content)}
                  <div className="imgcontainer">
                    <Card.Img
                      variant="top"
                      src={
                        data.thumbnail
                          ? data.thumbnail
                          : "https://miro.medium.com/max/968/1*F6SrJR7_s95r6oCF3ugMZw.png"
                      }
                    />
                  </div>
                  <Card.Body className="card-bodyx">
                    <Card.Title>{data.title}</Card.Title>
                    {data.categories.map((cat) => (
                      <Badge
                        bg="light"
                        text="dark"
                        style={{
                          fontSize: "0.81rem",
                          margin: "2px",
                          marginBottom: "0px",
                        }}
                      >
                        {cat}
                      </Badge>
                    ))}
                    <br />
                    <br />
                    {/* <a href={data.link}>
                      <Badge
                        bg="primary"
                        // text="blue"
                        style={{ fontSize: "0.81rem", margin: "10px" }}
                      >
                        Link
                      </Badge>
                    </a> */}

                    <h6
                      style={{
                        padding: "2%",
                        fontWeight: "200",
                        color: "grey",
                        fontSize: "0.71rem",
                        alignSelf: "flex-end",
                      }}
                    >
                      Published on {data.pubDate}
                    </h6>
                  </Card.Body>
                </Card>
              </a>
            ))
          ) : (
            <>Unable to display article. Please try after sometime</>
          )}
        </div>
      </div>
    </div>
  );
};

export default Blogs;
