import React from "react";

const Sectionlta = () => {
  return (
    <div
      style={{
        padding: "10%",
        justifyContent: "center",
        textAlign: "justify",
      }}
    >
      <p><b>What is Leave Travel Allowance (LTA)?</b> </p>
      <p>
      Employees receive LTA from their employers
      for travelling while they are on leave. This allowance is partially
      taxable under the Income Tax Act. </p>
      
      <p><b>What are the conditions to claim LTA?</b></p>
      <p>Here are certain factors that have to be considered before claiming LTA
      benefit. 
      <li>Only domestic travel is covered Actual journey should be
      undertaken Covers employee and family members, where family members
      include spouse, children, brother, sister and wholly dependent parents.</li>
      <li>Allowed for a maximum of two children, if born after 1st October 1998 LTA
      benefit cannot be claimed for travel on official holidays and weekends.</li>
      <li>Employees should apply for leaves and travel during that period.</li>
      </p>

      <p><b>What is the maximum LTA benefit? </b></p>
      
      <b>The LTA benefit amount is limited to: </b>
      <li>Actual travel cost – Rail, air or bus fare. No expenses such as local conveyance,
      sightseeing, hotel accommodation, etc., are eligible, or </li>
      <li>Actual LTA
      provided by the employer</li> 
      
      <p>Whichever is lower. </p>
      
      <p>
      <li>If LTA provided by the
      employer is Rs 40,000, and actual travel cost incurred by the employees is
      Rs 32,000. Then, the LTA benefit will be Rs 32,000 only. Remaining, Rs
      8,000 will be added to the employees taxable income. </li>
      <li>Employees are
      allowed only two journeys in a block of four calendar years. The block
      applicable for the current year is 2018-21. </li>
      <li>To claim this benefit,
      employees must submit travel proofs like a boarding pass, train tickets,
      travel agency invoice, etc. to their employers. These proofs should be
      submitted along with the mandatory declaration.</li>
      </p>
    </div>
  );
};

export default Sectionlta;
